import Immutable from '@lib/base/seamless-immutable';
import Reduxable from './Reduxable'

export default (...args) => {
	const initialState = typeof args[0] !== 'function' && args.shift();
	const reducers = []
	// reduxable converter
	let children = null
	args.forEach(reducer => {
		let key = null
		let i = 0
		if(Array.isArray(reducer)){ // [ key, reducerFunction ]
			key = reducer[0]
			reducer = reducer[1]
		}
		if (typeof reducer === 'function') {
			reducers.push([key, reducer])
		} else {
			// reduxable
			if(!children) children = {}
			if(key == null){
				children['$root:' + (i++)] = reducer
			}else{
				children[key] = reducer
			}
			reducers.push([key, reducer.reduce])
			if (reducer._setScope) {
				reducer._setScope(key)
			}
		}
	})

	if (typeof initialState === 'undefined') {
		throw new TypeError(
			'The initial state may not be undefined. If you do not want to set a value for this reducer, you can use null instead of undefined.'
		);
	}

	const result = (prevState, value, ...args) => {
		const prevStateIsUndefined = typeof prevState === 'undefined';
		const valueIsUndefined = typeof value === 'undefined';

		if (prevStateIsUndefined && valueIsUndefined && initialState) {
			return initialState;
		}

		return reducers.reduce((newState, [key, reducer], index) => {
			if (typeof reducer === 'undefined') {
				throw new TypeError(
					`An undefined reducer was passed in at index ${index}`
				);
			}

			if(key == null){ // flat merge
				return reducer(newState, value, ...args);
			}else{
				if(!newState) newState = new Immutable({})
				key = key.split('.')
				return newState.setIn(key, reducer(newState.getIn(key), value, ...args));
			}
		}, prevStateIsUndefined && !valueIsUndefined && initialState ? initialState : prevState);
	};
	if(children === null) return result

	return new Reduxable(children, result)
};