// import Util from "@lnw/util";
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'common/helpers/_';
import createReactClass from 'create-react-class'
import { updateServerContext } from '@lnw/base/serverContext'

// -- Component wrapper for call api when component is loading.
/**
 *  apiPreload((props, isServer) => {
 *    return props.loadProductData(props.params.id)
 *  })(Components)
 *
 *  apiPreload((props, isServer) => {
 *    return [
 *      props.loadProductData(props.params.id),
 *      props.loadSubProductData(props.params.id),
 *    ]
 *  })(Components)
 */
/*
* options = {
* 	pageCacheTime: (time in minutes) // server rendering cache
* 	redirectUrl: (url) // redirect to new url
*   missed: boolean // display as 404
* }
* */

export const apiPreload = (apiRun, options = {}) => (component, clone) => {
	// -- use in server rendering -> no ui code or jquery in here
	if (!apiRun) return component

	let newComponent = null

	// convert stateless to class
	if (typeof component == 'function' && !(component.prototype instanceof React.Component)) {
		const renderer = component;
		newComponent = createReactClass({
			propTypes: renderer.propTypes,
			displayName: renderer.name,
			getDefaultProps: () => renderer.defaultProps || {},

			render() {
				return renderer(this.props)
			},
		});
		newComponent.constructor.displayName = renderer.name
		// newComponent.constructor.name = renderer.name
		try { // IOS8 / safari 8 bug
			Object.defineProperty(newComponent.constructor, 'name', { value: renderer.name });
		} catch (e) {
		}
	} else if (clone) {
		const name = component.name || component.displayName

		newComponent = function () { // inheritance
			component.apply(this, arguments)
		}

		// newComponent.prototype = Object.create(component.prototype);
		// newComponent.prototype.constructor = newComponent;
		newComponent.prototype = _.create(component.prototype, {
			constructor: newComponent,
		});
		newComponent.constructor.displayName = name
		// newComponent.constructor.name = name
		try { // IOS8 / safari 8 bug
			Object.defineProperty(newComponent, 'name', { value: name });
		} catch (e) {
		}
	} else {
		newComponent = component
	}

	if (!newComponent.contextTypes) {
		newComponent.contextTypes = component.contextTypes || {}
	}
	// Server promise
	newComponent.contextTypes.serverFetchPromises = PropTypes.array
	// Server context
	newComponent.contextTypes.serverContext = PropTypes.object

	const oldComponentWillMount = newComponent.prototype.componentWillMount
	let alreadyInited = false

	// server only
	newComponent.prototype.updateServerContext = function (_options = null) {
		let { serverContext } = this.context;
		if(!serverContext) return null
		if(!_options) _options = options
		// server rendering context
		serverContext.apiPreloadInited = true
		if(!serverContext.apiPreloadComponent)
			serverContext.apiPreloadComponent = []
		serverContext.apiPreloadComponent.push(component.name || component.displayName)
		this.context.serverContext = updateServerContext(_options, serverContext)

		return this.context.serverContext
	}

	newComponent.prototype.componentWillMount = function () {
		// const self = this
		// if (!alreadyInited)
		{
			// server only
			// console.log('name', this.constructor.displayName || this.constructor.name)
			const { serverFetchPromises, serverContext } = this.context;
			if (serverFetchPromises) { // isServer
				alreadyInited = true

				// server rendering context
				serverContext.apiPreloadInited = true

				this.updateServerContext()

				let promises = apiRun.bind(this)(this.props, true, this)
				// console.log('apiRun', newComponent.constructor.displayName , promises)
				if (promises) {
					if (!Array.isArray(promises)) promises = [promises]
					promises.forEach((promise) => {
						if (promise) {
							// get promise from payload (apiMiddleware)
							if (promise.payload && promise.payload.promise) promise = promise.payload.promise
							if (promise.promise) promise = promise.promise
							if (promise.then && typeof promise.then == 'function') {
								// if (!promise.repeatCall) { // remove api repeat call
								// 	// console.log('server promised', promise, self.constructor.displayName || self.constructor.name)
								// }
								serverFetchPromises.push(promise);
							}
						}
					})
				}
			}
		}

		if (oldComponentWillMount) oldComponentWillMount.bind(this)()
	}

	const oldComponentDidMount = component.prototype.componentDidMount
	newComponent.prototype.alreadyInited = false
	newComponent.prototype.componentDidMount = function () {
		// console.log('componentDidMount')
		if (!this.alreadyInited)
		{
			// console.log('componentDidMount2')
			apiRun.bind(this)(this.props, false, this)
			this.alreadyInited = true
		}
		if (oldComponentDidMount) oldComponentDidMount.bind(this)()
	}

	newComponent.prototype.apiRun = function (props = null) {
		return apiRun.bind(this)(props || this.props, false)
	}

	return newComponent
}
