// Easy to switch between immutable-js and seamless-immutable for migrate / test purpose

import { Util } from '@lnw/util';

import Immutable from '@lib/base/seamless-immutable';
import _ from '@lib/base/_';

// import Immutable from 'common/lib/3rdparty/seamless-immutable';
// import _transit from 'common/lib/transit-seamless-immutable';

// ------------------ Immutable js
// https://facebook.github.io/immutable-js/docs/#/
// export { Map, fromJS, Seq, Record, List } from 'immutable'

// export { default as transit } from 'transit-immutable-js'
// export const transit = _transit
// export const withRecords = _transit.withRecords
// export const toJSON = _transit.toJSON
// export const fromJSON = _transit.fromJSON

// ------------------ Seamless immutale
// https://github.com/rtfeldman/seamless-immutable
// const Immutable = require('seamless-immutable')


export type ImmutableType = {
	get: (key : string) => any;
	getIn: (key : Array) => any;
	set: (path: string, value: any) => ImmutableType;
	setIn: (path: Array | string, value: any) => ImmutableType;
	has: (key : string) => boolean;
	hasIn: (key : string) => boolean;
	deleteIn: (key : string) => ImmutableType;
	toJS: (deep) => any;
	asMutable: (deep) => any;
	isEmpty: () => boolean;
	map: (f) => any;
	update: (path: string, f ) => ImmutableType;
	updateIn: (path: Array | string, f) => ImmutableType;
	merge: (obj: any, options?: any) => ImmutableType;
	replace: (obj: any, options?: any) => ImmutableType;
}

const callHelper = function (func, self, _arguments) {
	const args = _arguments.slice().unshift(self)
	return func.apply(args)
}


const ImmutableFrom: ImmutableType = (obj = {}, type) =>
	Immutable.from ? Immutable.from(obj, { prototype: ImmutableHelperPrototype(type, typeof obj) }) : obj

export const Map: ImmutableType = (value = {}) => ImmutableFrom(value, '__Map')
export const List: ImmutableType = (value = []) => ImmutableFrom(value, '__List')
export const fromJS: ImmutableType = ImmutableFrom
export const Seq: ImmutableType = (value = []) => ImmutableFrom(value, '__Seq')
export const toJS: any = (value, deep) => Immutable.asMutable(value, { deep })
export const toJSON: any = (value, deep) =>
	// const tmp = JSON.parse(JSON.stringify(_.merge({}, value)))
	// //tmp.prototype = null
	// console.log(tmp, tmp.prototype, tmp.constructor.name , value.hasOwnProperty('_type'))
	// return tmp
	// // return Immutable.asMutable(_.merge({}, value), { deep: true })
	deep ? Util.mergeDeep({}, value) : Util.assingMerge({}, value)


// immutable helper
const _proto = {
	get(k) {
		return _.get(this, k)
	},

	// TODO: fix safari bug
	// empty asMutable will return getIn + setIn method
	// getIn(k) {
	// 	return _.get(this, k)
	// },
	// setIn(path, value) {
	// 	return Immutable.setIn(this, path, value);
	// },

	has(k) {
		return _.has(this, k)
	},
	hasIn(k) {
		return _.hasIn(this, k)
	},
	// delete(k) { return _.unset(this,k) },
	deleteIn(k) {
		return Immutable.without(this, k)
	},
	toJS(deep) {
		return Immutable.asMutable(this, { deep })
	},
	isEmpty() {
		return Util.empty(this)
	},
	map(f) {
		return _.map(this, f)
	},
	//
	// set: function() { return callHelper(Immutable.set, this, arguments) },
	// setIn: function() { return callHelper(Immutable.setIn, this, arguments) },
	// update: function() { return callHelper(Immutable.set, this, arguments) },
	// updateIn: function() { return callHelper(Immutable.setIn, this, arguments) },
	// asMutable: function() { return callHelper(Immutable.asMutable, this, arguments) },
	//
	// //object
	// merge: function() { return callHelper(Immutable.merge, this, arguments) },
	// replace: function() { return callHelper(Immutable.replace, this, arguments) },
	// without: function() { return callHelper(Immutable.without, this, arguments) },
	//
	// //array
	// flatMap: function() { return callHelper(Immutable.flatMap, this, arguments) },
	// asObject: function() { return callHelper(Immutable.asObject, this, arguments) },
}
export const ImmutableHelperPrototype: ImmutableType = (_name, _type, prototype = {}) => {
	if (_name) prototype._name = _name
	if (_type) prototype._type = _type

	// if (prototype == Array.prototype) delete proto.map
	// if (prototype == Object.prototype) {
	//   delete proto.isEmpty; delete proto.has; delete proto.get; delete proto.map
	// }

	_.map({ ..._proto}, (f, k) => {
		// console.log(k)
		if (!prototype[k]) prototype[k] = f
	})
	return prototype
}

// ImmutableHelperPrototype(null, null, Object.prototype)
// ImmutableHelperPrototype(null, null, Array.prototype)

// const ImmutableFix = (object) => {
//   const deleteFn = ['setIn', 'update', 'updateIn', 'asMutable', 'merge', 'replace', 'without', 'flatMap', 'asObject']
//   deleteFn.map((fn) => {
//     if (object.hasOwnProperty(fn) && typeof object[fn] == 'function') delete object[fn]
//   })
//   return object
// }


export const merge = Immutable.merge

export const recordTemplate = {}
export const recordCreator = {}

if (process.env.IS_BROWSER) window.recordCreator = recordCreator


export const Record = (defaultValue, name, prototypeOrClass) : ImmutableType => {
	// get name from prototype
	if (!name && prototypeOrClass.name) name = prototypeOrClass.name
	if (!name) throw new Error('Record name must not empty.')
	if (recordTemplate[name]) {
		// fix for hot reload
		if (!module.hot && !require('@lib/base/common').isServer()) {
			throw new Error('Record name \'' + name + '\' already declared.')
		}
	}

	const recordName = 'R_' + name

	if (!prototypeOrClass) {
		const R_Record = function () {
			return this
		};
		if (process.env.IS_BROWSER) {
			// fix bug for chrome dev + babel = auto anon function name
			eval('R_Record = function ' + recordName + ' () { return this }');
		}
		// const R_Record = [recordName];
		R_Record.prototype.name = function () {
			return recordName
		}

		// R_Record._name = recordName
		prototypeOrClass = R_Record
		// prototypeOrClass.toString = function() { return recordName }
		try { // TODO: android browser bug (react native)

			Object.defineProperty(prototypeOrClass, 'name', { value: recordName });
			Object.defineProperty(prototypeOrClass.prototype, 'name', { value: recordName });
			Object.defineProperty(prototypeOrClass.prototype.constructor, 'name', { value: recordName });
		// try { // IOS8 / safari 8 bug
			Object.defineProperty(prototypeOrClass, 'name', {
				get() {
					return recordName
				}
			});
		} catch (e) {
		}
		prototypeOrClass._name = recordName
	}else{
		//extend default value
		if(prototypeOrClass.defaultValue){
			if(typeof defaultValue == 'function'){
				const _defaultValue = defaultValue;
				defaultValue = () => { return { ...prototypeOrClass.defaultValue, ..._defaultValue() }}
			}else{
				defaultValue = { ...prototypeOrClass.defaultValue, ...defaultValue }
			}
		}
	}


	if (prototypeOrClass && prototypeOrClass.prototype) {
		prototypeOrClass = prototypeOrClass.prototype
	}

	const defaultValue2 = typeof defaultValue == 'function' ? defaultValue() : defaultValue
	recordTemplate[name] = Immutable(defaultValue2,
		{ prototype: ImmutableHelperPrototype(recordName, 'object', prototypeOrClass) })
	try { // TODO: android browser bug (react native)
		Object.defineProperty(recordTemplate[name].constructor, 'name', { value: recordName });
	}catch(e) {}

	class creator {
		static recordName = recordName
		static defaultValue = defaultValue2
		constructor(value) {
			if (typeof value != 'object') value = {}
			let base
			if (typeof defaultValue == 'function'){
				base = Immutable(defaultValue(),
					{ prototype: ImmutableHelperPrototype(recordName, 'object', prototypeOrClass) })
				try { // TODO: android browser bug (react native)
					Object.defineProperty(recordTemplate[name].constructor, 'name', { value: recordName });
				}catch(e) {}
			}else{
				base = recordTemplate[name]
			}
			return Immutable.merge(base, value || {}, { deep: true })
		}
	}

	// const creator = function (value) {
	//   // console.log('r',value,defaultValue,name)
	//   if (typeof value != 'object') value = {}
	//   return Immutable.merge(recordTemplate[name], value || {} , { deep: true })
	// }
	try { // IOS8 / safari 8 bug
		Object.defineProperty(creator, 'name', { value: recordName });
		Object.defineProperty(creator.prototype, 'name', { value: recordName });
	} catch (e) {
	}

	creator._name = recordName
	recordCreator[name] = creator

	return creator
}

// export const Record = (defaultValue, name, prototypeOrClass) => {
//
//   // get name from prototype
//   if (!name && prototypeOrClass.name) name = prototypeOrClass.name
//   if (!name) throw new Error('Record name must not empty.')
//   if (recordTemplate[name]) {
//     // fix for hot reload
//     if (!module.hot) {
//       throw new Error('Record name \'' + name + '\' already declared.')
//     }
//   }
//
//   const recordName = 'R_' + name
//
//   if (!prototypeOrClass) {
//     const R_Record = function() { return this };
//     if(process.env.IS_BROWSER){
//       // fix bug for chrome dev + babel = auto anon function name
//       eval("R_Record = function "+ recordName +" () { return this }");
//     }
//     // const R_Record = [recordName];
//     R_Record.prototype.name = function() { return recordName }
//
//     // Object.defineProperty(R_Record, 'name', { value: recordName });
//     // Object.defineProperty(R_Record.prototype, 'name', { value: recordName });
//     // R_Record._name = recordName
//     prototypeOrClass = R_Record
//     // prototypeOrClass.toString = function() { return recordName }
//     Object.defineProperty(prototypeOrClass, 'name', { value: recordName });
//     Object.defineProperty(prototypeOrClass.prototype, 'name', { value: recordName });
//     Object.defineProperty(prototypeOrClass.prototype.constructor, 'name', { value: recordName });
//     Object.defineProperty(prototypeOrClass, 'name', { get: function() { return recordName } });
//     prototypeOrClass._name = recordName
//   }
//
//   if (prototypeOrClass && prototypeOrClass.prototype){
//
//     prototypeOrClass = prototypeOrClass.prototype
//   }
//
//   recordTemplate[name] = Immutable(defaultValue,
//     { prototype: ImmutableHelperPrototype(recordName, 'object', prototypeOrClass) })
//   Object.defineProperty(recordTemplate[name].constructor, 'name', { value: recordName });
//
//   class creator {
//     constructor(value) {
//       if (typeof value != 'object') value = {}
//       return Immutable.merge(recordTemplate[name], value || {} , { deep: true })
//     }
//   }
//
//   // const creator = function (value) {
//   //   // console.log('r',value,defaultValue,name)
//   //   if (typeof value != 'object') value = {}
//   //   return Immutable.merge(recordTemplate[name], value || {} , { deep: true })
//   // }
//   Object.defineProperty(creator, 'name', { value: recordName });
//   Object.defineProperty(creator.prototype, 'name', { value: recordName });
//
//   creator._name = recordName
//   recordCreator[name] = creator
//
//   return creator
// }
// export const transit = _transit

// TODO: debug
// if (typeof window != 'undefined') window.Immutable = Immutable

