/* @flow weak */
import { Map } from 'common/immutable';
import { createTransform } from 'redux-persist';
// import { fromJSON, toJSON } from 'common/transit';

import { default as moduleStorages, callback as storageCallback } from '_config/common/storages';

import _ from 'common/helpers/_';
import {isCacheDisabled} from "@lib/base/helper";


const SAVE_DEBOUNCE = 100; // 33;

const whitelist = [];
const savePaths = [
	['fields'],
	// sample
	// ['moduleName', 'record1', 'record2.field1', [ 'record2', 'field2' ] ],
	...moduleStorages,
];

const pathFilter = (state, paths = []) => {
	let subset = Map();
	paths.forEach(path => {
		// if (state.hasIn(path)) {
		//   subset = subset.setIn(path, state.getIn(path));
		// }
		if (_.hasIn(state, path)) {
			// subset = _.set(subset, path, _.get(state, path));
			if(typeof path === 'string') path = path.split('.')
			subset = subset.setIn(path, _.get(state, path));
		}
	});
	return subset;
};


const mergeDuplicatePath = (paths) => {
	// find same feature
	let output = {}
	let cached = {}
	paths.map(([feature, ...path]) => {
		if(feature && feature.indexOf('cache::') == 0) {
			feature = feature.replace('cache::','')
			if(path.length){
				if(cached[feature] === true) return// root
				if(!cached[feature]) cached[feature] = []
				cached[feature].push(...path)
			}else{
				cached[feature] = true // root
			}
		}
		if(path.length){
			if(output[feature] === true) return// root
			if(!output[feature]) output[feature] = []
			output[feature].push(path)
		}else{
			output[feature] = true // root
		}
	})
	let output2 = []
	_.map(output, (arr, feature) => {
		if(arr === true){
			output2.push([ feature ])
		}else{
			let tmp = [ feature ]
			arr.map(v => {
				tmp.push(...v)
			})
			output2.push(tmp)
		}
	})
	return [ output2, cached ]
}

const filterSavePaths = (initialState, storageSavePaths=[]) => {
	let [ paths, cachePaths ] = mergeDuplicatePath([ ...savePaths, ...storageSavePaths])

	return paths.map(([feature, ...path]) => {
			whitelist.push(feature);
			return createTransform(
				state => path.length ? pathFilter(state, path) : state, // inbound
				// state => getState()[feature].merge(state),
				state => {
					const {getState} = require('common/configure/configureStore');

					// disable cache (cache::)
					if(cachePaths[feature] && isCacheDisabled())
					{
						if(cachePaths[feature] === true){
							// ignore
							return getState()[feature]
						}else{
							// remove cache field from state
							cachePaths[feature].map(field => {
								if(state[field]) delete state[field]
							})
						}
					}
					// return getState()[feature] // on merge
					// return getState()[feature].merge(state, { deep: false }) // error -> lnw.editable.state.editing merge
					return getState()[feature].merge(state, {
						deep: true,
						merger: function replaceArrayMerger(current, other) {
							if (!(current instanceof Array) || !(other instanceof Array)) return;
							return other
						}
					})
					// let current = getState()[feature]
					// return getState()[feature].replace(_.merge(current, state))
					// return getState()[feature].merge(state, { deep: true })
				}, // outbound -> use current state instead of initial incase we do something before rehydated
				// state => initialState[feature].merge(state), // outbound
				{whitelist: [feature]},
			);
		})
	}
;

const transformImmutables = createTransform(
	// state => toJSON(state),
	// state => fromJSON(state)
	// TODO:
	// state => typeof state == 'string' ? state : JSON.stringify(state),
	state => JSON.stringify(state, null, null, function (k, v) {
		// if (process.env.NODE_ENV !== 'production') return null;
		throw new Error('\n      redux-persist: cannot process cyclical state.\n      Consider changing your state structure to have no cycles.\n      Alternatively blacklist the corresponding reducer key.\n      Cycle encounted at key "' + k + '" with value "' + v + '".\n    ');
	}),
	state => JSON.parse(state)
);


// TODO: rework local storage system
// Rehydrating from disk always overrides the current value.
// That's perfect for fields, currentLocale, or viewer.
// But what if something is prefetched on the server? Then we would like
// to merge locally cached data with fresh data from the server.
// Research comparing to default store or timestamp/revision reducers.
// TODO: Add customUpdate.
// TODO: expire https://github.com/gabceb/redux-persist-transform-expire
const configureStorage = (initialState, storageEngine, storageSavePaths = []) => {
	const keyPrefix = `${initialState.config.appName}:${process.env.MAIN_MODULE}:`;
	return {
		keyPrefix,
		whitelist,
		debounce: SAVE_DEBOUNCE,
		storage: storageEngine,
		transforms: [...filterSavePaths(initialState, storageSavePaths), transformImmutables],
		callback: storageCallback,
		serialize: false
	};
};

export default configureStorage;
