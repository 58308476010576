/**
 *
 * withEditableTheme
 *
 */

// import { isServer } from "@lib/base/common";
import React from 'react';
import PropTypes from 'prop-types';
// import Loadable from "react-loadable";
import Loading from 'browser/components/Loading';

// import { View } from 'browser/components'
// import { reactCommon } from 'common/helpers'
// import { api } from 'common/helpers'
import { api } from '@lnw/api/api'
// import { getModuleState } from 'lnw/editable/common/helpers'
// import {} from 'lnw/editable/browser/components'
// import { action1, action2 } from 'lnw/editable/common/actions';
import { connect } from '@lib/base/redux';
import Util from '@lnw/util'

// import {updateServerContext} from "@lnw/base/serverContext";

class _WithApi extends React.Component {

	static propTypes = {
		children:  PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
		api: PropTypes.any.isRequired,
		loadingRenderer: PropTypes.any,
		updateServerPromise: PropTypes.bool,
		useState: PropTypes.bool,
		// preloadInServerMode: PropTypes.array,
	}

	static defaultProps = {
		updateServerPromise: true,
		useState: true,
	}

	static contextTypes = {
		serverFetchPromises: PropTypes.array,
		// serverContext: PropTypes.object,
	};


	constructor(props) {
		super(props);
		this.state = { loading:true, data: null, error: null } ;
		// debugger
		if(this.props.useState && this.props.withApiData){
			// console.log('withApiData', this.apiCacheKey(props.api),this.props.withApiData[this.apiCacheKey(props.api)])
			// console.log('withApiData', this.props.withApiData)
			this.state.data = this.props.withApiData[this.apiCacheKey(props.api)]
			if(!Util.empty(this.state.data)){
				this.state.loading = false
			}
		}
	}

	componentWillMount() {
		if(this.state.loading == false) return;
		this.loadCancel = false

		let promise = this.api(this.props)
		if(this.context.serverFetchPromises && this.props.updateServerPromise){
			// promise.then(v => console.log('updateServerPromise 3', v))
			this.context.serverFetchPromises.push(promise);
		}
		//
		// if(this.context.serverFetchPromises && this.props.preloadInServerMode){
		// 	console.log('this.props.preloadInServerMode', this.props.preloadInServerMode)
		//
		// 	return this.props.preloadInServerMode.map(c => {
		// 		let tmp = new c[0](c[1],this.context)
		// 		console.log('withApi',tmp.componentWillMount)
		// 		if(tmp.componentWillMount) tmp.componentWillMount()
		// 	})
		// }

	}

	componentWillReceiveProps(nextProps, nextContext){
		if(nextProps.id != this.props.id){
			// reload
			// const promise =
			console.log('nextProps.id',nextProps.id)
			this.api(nextProps)
		}
	}

	componentWillUnmount() {
		this.loadCancel = true
	}

	apiCacheKey(api = null) {
		let API = api || this.props.api
		if(typeof API == 'string') API = { url: api }
		return API.url + '::' + API.method + '=d' + (API.data ? JSON.stringify(API.data) : '')
			+ '=params' + (API.params ? JSON.stringify(API.params) : '')
	}

	loadCancel = false
	async api(props) {
		const self = this
		this.setState({ loading:true, data: null, error: null })
		let _api = props.api
		if(typeof _api == 'string') _api = { url: _api }
		try{
			let value = await api(_api);
			if(self.loadCancel) return value.data
			this.setState({ loading: false, data: value.data })

			if(this.context.serverFetchPromises && this.props.useState && this.props.doAddWithApiData){
				// console.log('doAddWithApiData',this.apiCacheKey(_api), value.data )
				this.props.doAddWithApiData(this.apiCacheKey(_api), value.data )
			}

			return value.data
		}catch(error){
			this.setState({ loading: false, data: null, error })
			console.error('withApi', error)
			return error
		}
	}


	render() {
		const { api, children, loadingRenderer } = this.props
		const { loading, data, error } = this.state
		if(loading){
			if(!this.context.serverFetchPromises || !this.props.updateServerPromise){
				if(loadingRenderer) {
					if(typeof loadingRenderer !== 'function') return loadingRenderer
					return loadingRenderer()
				}
				return <Loading center={true}/>
			}
		}
		if(typeof children == 'function'){
			return children(data, error)
		}
		return React.cloneElement(children, {
			data, error
		})
	}
}

export const WithApi = connect([
	'getWithApiData',
	'doAddWithApiData'
])(_WithApi)