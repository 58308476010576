/*eslint-disable */

// import CONST, { getConst } from "_config/common/const";
import CONST from "@lib/base/const";

import { getMainModuleName } from "./helper";

import camelCase from 'lodash/camelCase'

require('@lnw/base/global')

export const Url = {
	base: function (uri) {
		// console.log('CONST.BASE_URL',CONST.BASE_URL)
		if(uri && uri.indexOf('://') !== -1) return uri
		return (CONST && CONST.HTTPS ? "https" : "http") + "://" + CONST.BASE_URL.trimChar("/") + "/" + (uri === undefined ? "" : (uri + "").trimChar("/"));
	},

	route_base: function (pathname){
		if(!pathname) return "/"
		if(CONST && CONST.ROUTE_BASE_URL && CONST.ROUTE_BASE_URL.trimChar("/")){
			return "/" + CONST.ROUTE_BASE_URL.trimChar("/") + "/" + pathname.trimChar("/")
		}
		return "/" + pathname.trimChar("/")
	},

	asset: function (uri) {

		if (uri && uri.indexOf("://") != -1) return uri

		if(CONST.ASSET_REVISION && uri && uri.indexOf('.js') == -1 && uri.indexOf('.css') == -1 && uri.indexOf('/lnwf/') == -1){
			uri += (uri.indexOf('?')==-1?'?':'&') + 'rev=' + CONST.ASSET_REVISION
		}

		if (CONST.ASSETS_URL == '/' || !CONST.ASSETS_URL) {
			return '/assets/' + (uri === undefined ? "" : (uri + "").trimChar("/"));
		}

		if (CONST.ASSETS_URL && CONST.ASSETS_URL.indexOf("://") != -1) {
			return CONST.ASSETS_URL.trimChar("/") + '/' + (uri === undefined ? "" : (uri + "").trimChar("/"));
		}

		return (CONST.HTTPS ? "https" : "http") + "://" + (CONST.ASSETS_URL.trimChar("/") || (CONST.BASE_URL.trimChar("/") + "/assets"))
			+ '/' + (uri === undefined ? "" : (uri + "").trimChar("/"));
	},

	module_asset: function (uri, module) {

		if (uri && uri.indexOf("://") != -1) return uri

		if(CONST.ASSET_REVISION && uri.indexOf('.js') == -1 && uri.indexOf('.css') == -1 && uri.indexOf('/lnwf/') == -1){
			uri += (uri.indexOf('?')==-1?'?':'&') + 'rev=' + CONST.ASSET_REVISION
		}

		if(module) module = camelCase(module)

		if (CONST.ASSETS_URL == '/' || !CONST.ASSETS_URL) {
			return '/assets/' + (module || getMainModuleName()) + '/' + (uri === undefined ? "" : (uri + "").trimChar("/"));
		}

		if (CONST.ASSETS_URL && CONST.ASSETS_URL.indexOf("://") != -1) {
			return CONST.ASSETS_URL.trimChar("/") + '/' + (module || getMainModuleName()) + '/' + (uri === undefined ? "" : (uri + "").trimChar("/"));
		}

		return (CONST.HTTPS ? "https" : "http") + "://" + (CONST.ASSETS_URL || (CONST.BASE_URL + "/assets")).trimChar("/") +
			'/' + (module || getMainModuleName()) + '/'
			+ (uri === undefined ? "" : (uri + "").trimChar("/"));
	},

	api: function (path, _CONST = null) {
		if(!_CONST) _CONST = CONST
		let base_url = _CONST.API.BASE_URL.replace('http://','').replace('https://','')
		return (_CONST.API.HTTPS ? 'https://' : 'http://') + base_url +
			(path.substr(0, 1) !== '/' ? '/' +
				(_CONST.API.VERSION ? (_CONST.API.VERSION + '/') : '')
				+ path : path);
		// return Util.ApiUrl(uri);
	},

	img: function (path, w, h, type, resize_type) {
		if (path && path.match(/^http(s)?:\/\//)) {
			return path
		} else if (path && path.indexOf('http://') == 0) {
			return path
		} else {
			return Url.base(path)
		}
	},

	_withPattern: function(pattern, data, allKeys) {
		let url = pattern
		allKeys.map(key => {
			if(data[key]){
				url = url.replace(`[${key}]`, data[key])
			}else{
				// remove
				url = url.replace(`/[${key}]`, '').replace(`[${key}]`, '')
			}
		})
		return url
	},

	// wrap func with base
	_wrap: function (TargetUrl, funcNames, baseFunc = null) {
		funcNames.map(funcName => {
			TargetUrl[funcName] = Url._wrapBaseFunc(TargetUrl[funcName], baseFunc)
		})
		return TargetUrl;
	},

	_wrapBaseFunc: function(func, baseFunc = null) {
		if(!baseFunc) baseFunc = Url.base
		return function () {
			return baseFunc(func.apply(null, arguments))
		}
	}

}

export default Url
