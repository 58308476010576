
import { isCacheDisabled } from "@lib/base/helper";
import axios from 'axios';
import Util from '@lnw/util';
import Cache from '@lnw/base/cache';
import Immutable from '@lib/base/seamless-immutable';
// import Immutable from 'common/lib/3rdparty/seamless-immutable';

export type ApiConfigType = {
	url: String,
	method: 'get' | 'post' | 'put' | 'delete',
	data: Object,
	headers: {
		'Content-Type': String
	},

	cacheKey: String, // null = auto generate
	cacheEnable: Boolean, // auto enable if 'get'
	cacheTime: Number, // minute
	loaderEnable: Boolean,

	transformRequest: [Function],
	xhrFields: Object,

	// ---- callback
	processData: ({ payload: Object, deps: any }) => Object, // run before success
	success: ({ payload: Object, deps: any }) => Object, // run after success : ({ skip: true, payload: value, ...deps })
	complete: (error: Object) => null, // run after complete (success + error) = final
	error: (error: Object) => null, // run after error : (error)
}

const axiosGlobalOptions = {}

export const setupGlobalOptions = (k , v) => {
	axiosGlobalOptions[k] = v
}


let Loader = null
// Setup loader UI for API system
export const setupApiLoader = (_Loader) => {
	Loader = _Loader && _Loader.set && _Loader.update ? _Loader : null
}

export const cachingAdapter = (config: ApiConfigType) => new Promise((resolve, reject) => {
	Cache.lruGet(config.cacheKey, null)
		.then((result) => {
			if (result == null || !result.headers) {
				axios.defaults.adapter(config).then(resolve, reject);
			} else {
				// if(result.asMutable) result = result.asMutable({ deep: true })
				if(result.asMutable) result = result.asMutable()
				result.cacheHit = true
				resolve(result);
			}
		}, (err) => {
			console.error('cache error', err)
			axios.defaults.adapter(config).then(resolve, reject);
		});
})


const urlParams = require('@lnw/util/util').default.urlParams()

let _apiClearCache = !!urlParams.nc
export const apiClearCache = (clearCache = true) => { // add nc to all api (clear server cache)
	_apiClearCache = clearCache // server cache
}

let _apiCacheDisable = isCacheDisabled()
export const apiCacheDisable = (disable = true) => { // disable all axios cache
	_apiCacheDisable = disable
}

export const cancelToken = () => axios.CancelToken.source()

const apiPromised = {}

export const api = (config: ApiConfigType, deps = {}) => {

	// localhost - use with remote login api
	// if(isLocalhost()){ // fix login
	axios.defaults.withCredentials = true;
	// }

	// default value
	const API = Util.merge({
		// xhrFields: !isLocalHost()?null:{
		//   withCredentials: true
		// },
		url: '',
		method: 'get',
		data: null, // post
		params: null, // get
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8', // '',
			// 'Content-Type': 'application/json'// 'application/x-www-form-urlencoded',
		},
        timeout: 20000,

		cacheKey: null, // null = auto generate
		cacheEnable: !config.method || config.method === 'get', // auto enable if 'get'
		cacheTime: 15,// process.env.IS_BROWSER ? 60 : 15, // minutes
		loaderEnable: true,

		transformRequest: [function (data) {
			// Do whatever you want to transform the data
			if (!data) return data;
			if (typeof data === 'object') {
				return Util.objectToParams(data)
			}
			return data
		}],

		// ---- callback
		processData: null, // run before success
		complete: null, // run after complete (success + error) = final
		success: null, // run after success : ({ skip: true, payload: value, ...deps })
		error: null, // run after error : (error)

		...axiosGlobalOptions,
	}, config)

	if (!API.url) throw new Error('API.url is undefined')

	debug('API.url', API.url)

	if(_apiClearCache){
		if(API.params){
			API.params.nc = 1
		}else{
			if(!API.data) API.data = {}
			API.data.nc = 1
		}
	}

	if(_apiCacheDisable) API.cacheEnable = false // disable all cache

	// get data to params
	if (API.method == 'get' && API.data && !API.params) {
		API.params = API.data
		delete API.data
	}

	// send browser url param to api
	if (API.method == 'get' && !Util.empty(urlParams)){
		if(!API.params) API.params = {}
		API.params._urlParams = JSON.stringify(urlParams)
	}

	if (Loader && API.loaderEnable) Loader.set(1)

	const errorValue = error => {
		let err = {}
		if(error && error.data && error.data.error) {
			if(typeof  error.data.error == 'string'){
				err = error.data
				err.name = err.error
			}	else{
				err = error.data.error
			}
		}else if(error && error.data) {
			// err.message = error.data
			err.stack = error.data
		}

		if(error && error.exception){
			err.name = error.exception.message
			err.message = error.exception.message
			err.stack = error.exception.stack
		}
		if(error && error.stack && error.message){
			err.name = error.message
			err.message = error.message
			err.stack = error.stack
		}
		return { name: 'Unknown error', ...err, source: API, errorObject: error }
	};

	const errorCallback = reject => error => {
		error = errorValue(error)

		// console.trace('ax error', error, API)
		reject && reject(error)

		if (API.error && typeof API.error === 'function') {
			API.error(error)
		}

		if (API.complete && typeof API.complete === 'function') {
			API.complete(error)
		}

		if (Loader && API.loaderEnable) Loader.update()

		if (API.cacheKey){
			// clear promise
			apiPromised[API.cacheKey] = null
		}

		// return error
	}

	const successCallback = (resolve, reject, cacheSave) => value => {
		let ret = value
		try{
			if (value && value.length !== 0 && !Util.isError(value.data)) {
				//
				// Save cache
				if (cacheSave && API.cacheEnable && !value.cacheHit) {
					// TODO: localstorage cache?
					Cache.lruSet(API.cacheKey, Immutable.from({
						data: value.data,
						status: value.status,
						statusText: value.statusText,
						headers: value.headers,
						cacheHit: value.cacheHit,
					}, { deep: true }), API.cacheTime)
					// console.log('save', API.cacheKey)
				}
				if (API.processData && typeof API.processData === 'function') {
					value = API.processData({ payload: value, ...deps })
				}
				debug('API.url success', API.url)
				resolve(value)

				if (API.success && typeof API.success === 'function') {
					if (value.skipped) {
						if (typeof API.cancel === 'function') API.cancel()
						API.success({ skip: true, payload: value, ...deps })
					} else {
						API.success({ payload: value, ...deps })
					}
				}
				if (API.complete && typeof API.complete === 'function') {
					API.complete({ payload: value, ...deps })
				}
				if (Loader) Loader.update()
			} else {
				// Util.isError(value && value.data);
				reject(errorValue(value))
				ret = errorValue(value)
			}

			if (API.cacheKey){
				// clear promise
				apiPromised[API.cacheKey] = null
			}
		}catch(e){
			console.warn(e)
			value.exception = e
			reject(errorValue(value))
		}

		return ret
	}

	// dummy data
	if(API.dummy_data){
		debug('API.dummy_data', API.dummy_data)
		return new Promise(async (resolve, reject) => {
			let data = typeof API.dummy_data === "function" ? API.dummy_data() : API.dummy_data
			if(data && data.then) data = await data
			successCallback(resolve, reject, false)({ data })
		})
	}

	// cache
	if (API.cacheEnable) {
		API.adapter = cachingAdapter.bind(axios)
		if (!API.cacheKey) {
			API.cacheKey = '[api]' + API.url + '::' + API.method + '=d' + (API.data ? JSON.stringify(API.data) : '')
				+ '=params' + (API.params ? JSON.stringify(API.params) : '')
		}

		// if api with same key already running = wait and get the same value
		if (apiPromised[API.cacheKey] && typeof apiPromised[API.cacheKey].then === 'function') {
			const tmpPromise = new Promise((resolve, reject) => {
				// https://github.com/mzabriskie/axios
				apiPromised[API.cacheKey] = apiPromised[API.cacheKey]
					.then(value => {
						// check if can skip?
						if (typeof API.skip === 'function' && deps.getState) {
							if (!isCacheDisabled()){ // disable cache = ignore skip
								if (API.skip(deps.getState()) === true) {
									value.skipped = true // callback to apiMiddleware to create skip action
								}
							}
						}
						successCallback(resolve, reject, false)(value)
						return value
					})
					.catch(errorCallback(reject));
			})
			// console.log('API = ', API.cacheKey , ' repeat call => skip')
			tmpPromise.repeatCall = true // use in apiPreload to skip on repeat call promise
			return tmpPromise
		}
	}

	const promise = new Promise((resolve, reject) => {
		// https://github.com/mzabriskie/axios
		// console.log(API)
		axios(API).then(value => {
			if (!value || typeof value.data !== 'object' || (typeof value.data.error !== 'undefined' && value.data.error)) {
				errorCallback(reject)(value)
			} else {
				successCallback(resolve, reject, true)(value)
			}
			return value
		})
			.catch(errorCallback(reject));
	})

	if (API.cacheKey) {
		apiPromised[API.cacheKey] = promise
	}
	return promise
}
