// bluebirdjs.com/docs/why-bluebird.html
// const Bluebird = require('bluebird');
const Bluebird = require('bluebird/js/browser/bluebird.core');

// Warnings are useful for user code, but annoying for third party libraries.
Bluebird.config({
	warnings: false,
	longStackTraces: true
});
// https://github.com/babel/babel-loader#custom-polyfills-eg-promise-library
// require('babel-runtime/core-js/promise').default = Bluebird; // TODO: need?

//--- require('@babel/runtime/core-js/promise').default = Bluebird;

module.exports = Bluebird;
