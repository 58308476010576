import { isServer } from "@lib/base/common";
import PropTypes from 'prop-types';
import React from 'react';
import { Util } from '@lnw/util';

let globalIntl = null
export const intl = () => globalIntl

// TODO: remove react-intl?
export class GlobalIntl extends React.Component {

	static contextTypes = {
		intl: PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props)
	}

	componentWillMount() {
		globalIntl = this.context.intl
	}

	render() {
		return this.props.children
	}
}

let defaultMessages = require('../default.message').default
export const setupDefaultMessages = (message) => { // merge
	defaultMessages = { ...defaultMessages, ...message }
}

if (typeof localStorage != 'undefined') {
	setupDefaultMessages(JSON.parse(localStorage.getItem('__l')))
}


const camelize = (str) => {
	if (str.toUpperCase() === str) return str.toLowerCase()
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => index == 0 ? letter.toLowerCase() : letter.toUpperCase()).replace(/\s+/g, '').trim();
}

const key = (str) => {
	const out = camelize(str.replace(/[^\w\s]/gi, '')).replace(/\s+/gi, '')
	if (!out || out.length == 0) return Math.abs(Util.stringHash(str))
	return out
}

export const _l = (config, state /* or defaultValue */) => {
	if (!globalIntl) {
		console.warn('globalIntl is null', config, state)
		if (typeof config == 'string') {
			return config
		}else{
			return config.defaultMessage || config.id
		}
	}
	if (typeof config == 'string') {
		const message = config
		// find default in main lang file
		const ids = [
			`modules.${process.env.MAIN_MODULE}.${key(message)}`,
			`src.common.${key(message)}`,
			camelize(message),
			message,
		]
		for (let i = 0; i < ids.length; i++) {
			if (defaultMessages[ids[i]]) {
				config = defaultMessages[ids[i]]
				break;
			}
		}
		if (typeof config == 'string') {
			// new lang
			config = {
				id: `modules.${process.env.MAIN_MODULE}.${key(message)}`,
				defaultMessage: typeof state == 'string' ? state : config,
				key: key(message),
				origMessage: message,
			}
			// push new
			defaultMessages[config.id] = config
			if(!isServer()){
				console.warn('=== app/lang ===', JSON.stringify(defaultMessages, null, 2))
				if (typeof localStorage != 'undefined') {
					localStorage.setItem('__l', JSON.stringify(defaultMessages, null, 2))
				}
			}
		}
	}
	return globalIntl.formatMessage(config, state)
}
