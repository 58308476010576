/* @flow */
/* eslint-disable react/require-extension */


require('babel-polyfill')
window.Promise = require(
	'common/configure/configureBluebird');

const run = async () => {

	// import 'core-js/es6/map';
	// import 'core-js/es6/set';
	// require('core-js/modules/es6.symbol');
	// window.Promise = require(
	// 	'common/configure/configureBluebird');

	// const global = await import(/* webpackChunkName: "app" */ /* webpackPreload: true */
	// 	'@lnw/base/global');
	const global = require('@lnw/base/global');
	global.prototypeInit()


// console.log('index')
	if(typeof window != 'undefined' && window._ASSETS_URL){
		// console.log(' window._ASSETS_URL', window._ASSETS_URL)
		String.prototype.trimRight = function (charlist) {
			if (charlist === undefined) {
				charlist = ' ';
			}
			return this.replace(new RegExp(`[${charlist}]+$`), '');
		};
		__webpack_public_path__ = window._ASSETS_URL.trimRight('/') + '/'
		// console.log('__webpack_public_path__')
	}


// // performance track
// if(process.env.NODE_ENV == 'development') {
// //https://github.com/nitin42/react-perf-devtool
// 	const {registerObserver} = require('react-perf-devtool')
//
// // Simple, no?
// 	window.observer = registerObserver({ timeout: 2000 }, function(e) {
// 		console.dir(e)
// 	})
//
// // 	const { registerObserver } = require('react-perf-devtool')
// // // assign the observer to the global scope, as the GC will delete it otherwise
// // 	window.observer = registerObserver()
// // 	console.log('registerObserver')
// }

// // react render debug
// const React = require('react')
// if (process.env.NODE_ENV !== 'production') {
// 	const {whyDidYouUpdate} = require('why-did-you-update');
// 	whyDidYouUpdate(React);
// }

//----// paint time
	if(process.env.NODE_ENV === 'development'){
		try{
			const observer = new window.PerformanceObserver(list => {
				// list.getEntries().forEach(({name, startTime}) => {
				// 	console.log({name, startTime});
				// });
			});
//performance.getEntriesByType('paint')
			observer.observe({
				entryTypes: ['paint']
			});
		}catch(e){}
	}
//
// if (window.performance) {
// 	let performance = window.performance;
// 	let performanceEntries = performance.getEntriesByType('paint');
// 	performanceEntries.forEach( (performanceEntry, i, entries) => {
// 		console.log("The time to " + performanceEntry.name + " was " + performanceEntry.startTime + " milliseconds.");
// 	});
// } else {
// 	console.log('Performance timing isn\'t supported.');
// }

	// await import(/* webpackChunkName: "app" */ /* webpackPreload: true */ 'common/index')
	require('common/index')

	await import(/* webpackChunkName: "main" */ /* webpackPreload: true */ '_config/browser/index')

// Bootstrap environment
	if (process.env.IS_BROWSER) {
		await import(/* webpackChunkName: "main" */ /* webpackPreload: true */
			'browser/platformDeps/index')
	} // Not use when server rendering mode


	const onWindowIntl = async () => {

		// console.log = console.error
		// TODO: locale
		//
		// // App locales are defined in src/server/config.js
		// const { addLocaleData } = require('react-intl');
		// const cs = require('react-intl/locale-data/cs');
		// const de = require('react-intl/locale-data/de');
		// const en = require('react-intl/locale-data/en');
		// const es = require('react-intl/locale-data/es');
		// const fr = require('react-intl/locale-data/fr');
		// const pt = require('react-intl/locale-data/pt');
		// const ro = require('react-intl/locale-data/ro');
		//
		// [cs, de, en, es, fr, pt, ro].forEach(locale => addLocaleData(locale));

		if (!window.Intl) {
			// TODO: locale
			await require.ensure([
				'intl',
				// 'intl/locale-data/jsonp/cs.js',
				// 'intl/locale-data/jsonp/de.js',
				'intl/locale-data/jsonp/en.js',
				// 'intl/locale-data/jsonp/es.js',
				// 'intl/locale-data/jsonp/fr.js',
				// 'intl/locale-data/jsonp/pt.js',
				// 'intl/locale-data/jsonp/ro.js',
				'intl/locale-data/jsonp/th.js',
			], (require) => {
				require('intl');
				// require('intl/locale-data/jsonp/cs.js');
				// require('intl/locale-data/jsonp/de.js');
				require('intl/locale-data/jsonp/en.js');
				// require('intl/locale-data/jsonp/es.js');
				// require('intl/locale-data/jsonp/fr.js');
				// require('intl/locale-data/jsonp/pt.js');
				// require('intl/locale-data/jsonp/ro.js');
				require('intl/locale-data/jsonp/th.js');
			}, "intl"); // chunk name
		}

		import(
			/* webpackChunkName: "main" */
			/* webpackPreload: true */
			'./main'
			);

		// require('./main')
	};

// github.com/andyearnshaw/Intl.js/#intljs-and-browserifywebpack
	onWindowIntl();
// require.ensure(['common/index', 'browser/platformDeps/index'], function(require) {
// 	onWindowIntl();
// });

}

run()

// https://medium.com/hackernoon/the-100-correct-way-to-split-your-chunks-with-webpack-f8a9df5b7758
// if (
// 	'forEach' in NodeList.prototype &&
// 	'startsWith' in String.prototype &&
// 	'endsWith' in String.prototype &&
// 	'includes' in String.prototype &&
// 	'includes' in Array.prototype &&
// 	'assign' in Object &&
// 	'entries' in Object &&
// 	'keys' in Object
// ) {
// 	import('babel-polyfill').then(run); // core-js shim
// }else{
// 	run()
// }


