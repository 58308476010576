export * from 'common/helpers'

export { default as CONST } from '_config/common/const'

import { getState } from 'common/helpers'


export const getModuleName = () => {
	return 'recruitment'
}

export const getModuleState = (state = null, main = false) => {
	state = state || getState()
	return state ? state[ main ? process.env.MAIN_MODULE : getModuleName() ] : null
}


