/* @flow weak */
// import { REHYDRATE } from 'redux-persist/constants';

import { CONST } from 'common/helpers';
// import { firebaseStart } from '@lib/redux-firebase/actions';

import _ from 'common/helpers/_'

export const APP_OFFLINE = 'APP_OFFLINE';
export const APP_ONLINE = 'APP_ONLINE';
export const APP_SET_LOCATION = 'APP_SET_LOCATION';
export const APP_SHOW_MENU = 'APP_SHOW_MENU';
export const APP_START = 'APP_START';
export const APP_STORAGE_LOAD = 'APP_STORAGE_LOAD';
export const APP_GLOBAL_UPDATE = 'APP_GLOBAL_UPDATE';
export const CLEAR_CACHE = 'CLEAR_CACHE';
// export const APP_STORAGE_RESET = 'APP_STORAGE_RESET';

export const setLocation = (location) => () => {
	if (CONST.ROUTE_BASE_URL) {
		location.base_pathname = '/' + location.pathname.replace(CONST.ROUTE_BASE_URL.trimRight('/'), '').trimLeft('/')
	} else {
		location.base_pathname = location.pathname
	}
	return {
		type: APP_SET_LOCATION,
		payload: { location },
	};
}


export const showMenu = (show: bool) => ({
	type: APP_SHOW_MENU,
	payload: { show },
});

export const resetStorage = () => ({ storageEngine }) => {
	storageEngine.clear()
	return null
}

export const start = () =>
	// const loadStorage = async (dispatch, storageEngine) => {
	//   const state = await storageEngine.load();
	//   dispatch({ type: APP_STORAGE_LOAD, payload: state });
	// };
	// return ({ dispatch, storageEngine }) => {
	//   loadStorage(dispatch, storageEngine).finally(() => {
	//     dispatch(firebaseStart());
	//   });
	//   return {
	//     type: APP_START,
	//   };
	// };
	({ dispatch }) => {
		// if (process.env.IS_BROWSER) dispatch({ type: REHYDRATE, payload: {} });

		if (process.env.IS_BROWSER) {
			// UI global update
			const UI = require("common/helpers/UI").default
			UI.unlockServerRenderDefault()
			const dispatchUI = _.debounce(() => {
				dispatch(globalUpdate({
					ui: {
						isBrowser: true,
						isMobileTemplate: UI.isMobileTemplate(true),
						isTabletTemplate: UI.isTabletTemplate(true),
						isDesktopTemplate: UI.isDesktopTemplate(true),
					},
					// const to state
					"const": require('_config/common/const').default
				}))
			}, 300)
			dispatchUI()

			// on window resize event
			if (typeof window !== 'undefined' && typeof $ !== 'undefined') {
				$(window).off('resize', dispatchUI);
				$(window).resize(dispatchUI);
			}

		} else {
			const { serverDefaultRenderAsMobile, default: UI } = require("common/helpers/UI")
			UI.unlockServerRenderDefault()
			dispatch(globalUpdate({
				ui: {
					isBrowser: false,
					// isMobileTemplate: false, // force desktop
					// isTabletTemplate: false,
					// isDesktopTemplate: true,
					//use serverDefaultRenderAsMobile
					isMobileTemplate: serverDefaultRenderAsMobile,
					isTabletTemplate: false,
					isDesktopTemplate: !serverDefaultRenderAsMobile,
				},
				// const to state
				"const": require('_config/common/const').default
			}))
		}

		// dispatch(firebaseStart());
		return {
			type: APP_START,
		};
	}
;


export const globalUpdate = (global) => ({
	type: APP_GLOBAL_UPDATE,
	payload: { global },
})

export const clearCache = () => ({
	type: CLEAR_CACHE,
	payload: {  },
})