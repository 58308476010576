// https://blog.rescale.com/addressing-valuelink-deprecation-in-react-15/

/*
<input
  onChange={linkState(this, 'company', ['budget'])}
  type="number"
  value={this.state.company.get('budget')}
/>

<input
  checked={this.state.showAdvancedOptions} // checked, not value!
  onChange={linkState(this, 'showAdvancedOptions')}
  type="checkbox"
/>
 */

import _ from 'common/helpers/_'

function createHandler(component, key, path = null, byPassFunction = null) {
	return e => {
		const el = e.target;
		const value = el.type === 'checkbox' ? el.checked : el.value;
		if (path) {
			component.setState({
				[key]: component.state[key].setIn ? component.state[key].setIn(path, value) :
					_.set(component.state[key], path, value)
			});
		} else {
			component.setState({
				[key]: value,
			});
		}
		if (byPassFunction){
			byPassFunction.bind(component)(e)
		}
	};
}

function linkState(component, key, path = null, byPassFunction = null) {
	if (path) {
		return createHandler(component, key, path, byPassFunction);
	}

	const cache = component.__linkStateHandlers ||
		(component.__linkStateHandlers = {});

	return e => {
		(cache[key] || (cache[key] = createHandler(component, key)))(e);
		if (byPassFunction) return byPassFunction.bind(component)(e)
	}
};

linkState.expand = (component, key, path = null, byPassFunction = null, valueFieldName = "value") => {
	let value = path ? _.get(component.state[key], path) : component.state[key]
	if(value === null || typeof value == 'undefined') value = ""
	return {
		onChange: linkState(component, key, path, byPassFunction),
		[valueFieldName] :	value
	}
}

export default linkState