
let CONST = require('_config/common/const').default
const _getConst = require('_config/common/const').getConst

// export const getConst = (url, createNew = false) => {
// 	if(createNew){
// 		return require('_config/common/const').getConst(url)
// 	}else{
// 		if(url !== false) require('_config/common/const').getConst(url, CONST) // update on same variable
// 		return CONST
// 	}
// }
export const getConst = (url, createNew) => {
	return _getConst(url, createNew)
}
export default CONST

