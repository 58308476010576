/* @flow */

// import autoReducer from "@lib/reduxable/src/autoReducer";
import initStoreHelper from "@lib/reduxable/src/initStoreHelper";
import combineReducers from './combineReducers'
import { assertReducersObject, assertState, assertChildName } from './assertions'

import warning from './utils/warning'


import type { ApiConfigType } from "@lnw/api/api";
import set from 'lodash/set'
import get from 'lodash/get'
import merge from 'lodash/merge'
import map from 'lodash/map'

type ApiConfigType2 = ApiConfigType & {
	autoReducer:
		Array | String | // ['field'] , 'field.field2'
		{
			paramsField: Array, // ['field', '...', 'params'],
			loadingField: Array, // ['field', '...', 'loading'],
			dataField: Array, // ['field', '...', 'data'],
			errorField: Array, // ['field', '...', 'error'],
		}
}

export type ReduxPayload = {
	API: ApiConfigType2
}

function isAReduxableSet(state) {
  if (!state || typeof state !== 'object') {
    return false
  }

  const firstChild = state[Object.keys(state)[0]]

  if (!firstChild) {
    return false
  }

  return typeof firstChild === 'function' || firstChild.__isReduxable
}



// ลอกจาก https://reduxbundler.com/api/bundle.html
// TODO: middlewares
// TODO: persistStore
// TODO: reactXdddsf


// https://github.com/reduxjs/reselect
// TODO: import { createSelector } from 'reselect'

export type ReduxableOptions = {
	init: (store:object) => null, // run once after createStore
	persistConfig: Array | boolean, // true = save all in localstorage, [ 'path1.path2', 'path3', ... ]
	persistCallback: (deps: any) => null, // callback once after localstorage rehydrated
	injectDeps: Object, // inject dependency to thunk
	// TODO: middlewareCreators: Array,
}


class Reduxable {

	static options:ReduxableOptions = {}

  /*
  * The Reduxable constructor receives
  * @param {any} state - Can be an object with other reduxables/reducers or any
  * @param {Object} reducers - An object with pure functions
  * @param {string | boolean | null} statceScope - null == auto scope, false == root scope, string == force scope
  */

  constructor(state = this.constructor.state, reducers = this.constructor.reducers, stateScope = null) {
    this.__isReduxable = true
		this.stateScope = stateScope
    assertState(state)

    if (isAReduxableSet(state)) {
      // TODO: assert valid reduxable set
      this._setupChildren(state)
      if(typeof reducers === "function"){ // force reduce function
				this.reduce = reducers
      }else{
				this.reduce = combineReducers(state)
      }
    } else {
      assertReducersObject(reducers)
      this._setupReducers(reducers)
      this._setupGlobalReducers(this.constructor.globalReducers)
      this._state = state
      this.reduce = this._getReducer()
    }
  }


  /*
  *  `state` getter
  *  ------------
  *  Returns the state for this particular scope
  *
  *  Given the following structure for a Redux store
  *
  *  const reduxStore = createStore(combineReducers({
  *    a: combineReducers({
  *      b: thisReduxableInstance
  *    })
  *  })
  *
  *  Then `thisReduxableInstance.state` will return `reduxStore.state.a.b`
  */

  get state() {
    if (!this.constructor._store) {
      return this._state
    }
    let rootState = this.constructor._store.getState()

		return this._getStateFromRootState(rootState)
  }

  get rootState() {
		if (!this.constructor._store) {
			return null
		}

		return this.constructor._store.getState()
	}

  /*
  *  `state` setter
  *  ------------
  *  Will set the state if not already set
  */

  set state(newState) {
    warning(`You can not set the state directly. You need to call a reducer to mutate the state`)
  }


	// call other reduxable selector
	reduxSelects(selectorNames, rootState = null){
		const store = this.constructor._store
		if(!store) return false
		return store.selects(selectorNames, rootState)
	}

	// call other reduxable action
	reduxAction(actionName, ...args){
		const store = this.constructor._store
		if(!store) return false
		return store.action(actionName, ...args)
	}

	// export all actions
	exportActions(){
		return this.__filterMethod(true, false)
	}


	createApiAction(reducerName, payload:ReduxPayload = {},
									url: String, method: String = 'get', data:Object = {}, autoReducer = false,
									apiOption:ApiConfigType2 = {}){
  	if(!payload) payload = {}
  	if(!payload.API) payload.API = {}
		payload.API = { ...payload.API, url, method, data, autoReducer, ...apiOption}

		// prefix autoReducer -- only use in reducerHelpers
		// TODO: debug?
		let scope = this.stateScope || this._autoReducerScope || this._scope
		if(scope){
  		autoReducer = payload.API.autoReducer
			if(autoReducer === true){
				autoReducer = reducerName
			}
			//prefix?
			if(typeof autoReducer === 'string') {
				autoReducer = scope + '.' + autoReducer

			}else if(Array.isArray(autoReducer)){
				const _scope = scope.split('.')
				autoReducer = [ ..._scope, ...autoReducer ]
			}else{
  			const _scope = scope.split('.')
				autoReducer = Object.keys(autoReducer).map(key => {
					if(typeof autoReducer[key] === 'string') autoReducer[key] = autoReducer[key].split('.')
					autoReducer[key] = [ ..._scope, ...autoReducer[key] ]
					return autoReducer[key]
				})
			}
			payload.API.autoReducer = autoReducer
		}

  	return this._createAction(reducerName, payload)
	}

	/*
	*  Returns a reducer function grouping all the defined reducers
	*
	*  This method will do the following
	*    1) Check that the action `scope` match with the scope of this Reduxable instance
	*    2) Find the method that matchs with the action `type`
	*    3) Call that method with the current state and the action `payload`
	*    4) Check that the new state retrieved by that method is not the same
	*       (i.e the method did not mutate the previous state)
	*/

  _getReducer() {
    return (state = this.state || this._state, { type, scope, payload, meta }) => {
    	const returnHelper = (ret) => {
    		if(ret === undefined){
    			console.error('reducer return undefined', type, scope, payload)
    			return state
				}
    		return ret;
			}
    	if(meta) {
    		if(!payload) payload= {}
    		payload.meta = meta
				// merge payload from meta
				if(meta.action && meta.action.payload){
					payload = { ...meta.action.payload, ...payload }
				}
			}

      const globalReducer = this._globalReducers[type]
      if (globalReducer) {
        return returnHelper(globalReducer(state, payload))
      }

      if (!this.constructor._global && scope !== this._scope) {
        return state
      }

      const scopedReducer = this._scopedReducers[type]

      if (scopedReducer) {
        return returnHelper(scopedReducer(state, payload))

      } else if(type.indexOf("_") !== -1){ // api type (FETCH_SUCCESS)

				// // autoReducer -- already run in reducersHelper
				// if(meta && meta.action.payload && meta.action.payload.API){
				// 	state = autoReducer(state, meta, payload)
				// }
				//

      	// nested reducers object (API_RUN_SUCCESS) -> [API_RUN , SUCCESS]
				// type = type.split('_', 2)
				const prefix = type.replace(/_[^_]+$/g,''); // API_RUN
				const suffix = type.match(/[^_]+$/g)[0]; // SUCCESS
				if(this._scopedReducers[prefix] && this._scopedReducers[prefix][suffix]){
					return returnHelper(this._scopedReducers[prefix][suffix](state, payload))
				}else{
					//default
					if(suffix === 'SUCCESS' && this._scopedReducers[prefix]){
						return returnHelper(this._scopedReducers[prefix](state, payload))
					}
					// console.warn('Reducer not found?')
				}
      }

      return state
    }
  }

  /*
  *  This method will:
  *  1) store the `reducers`
  *  2) define a method for each reducer that will call the reducer
  *     See the `_callReducer` method for more info
  *
  */

  _setupReducers(reducers) {
    this._scopedReducers = reducers
    this.reducers = {}
		this.actions = {}

    for (const reducerName in reducers) {
      if (reducers.hasOwnProperty(reducerName)) {
      	if(typeof reducers[reducerName] === 'object'){
					for (const reducerName2 in reducers[reducerName]) {
						if (reducers[reducerName].hasOwnProperty(reducerName2)) {
							const name = reducerName + "_" + reducerName2
							this.reducers[name] = (payload: ReduxPayload) => this._callReducer(name, payload)
							this.actions[name] = (payload: ReduxPayload) => this._createAction(name, payload)
						}
					}
				}else{
						// this[reducerName] = payload => this._callReducer(reducerName, payload)
						this.reducers[reducerName] = (payload: ReduxPayload) => this._callReducer(reducerName, payload)
						this.actions[reducerName] = (payload: ReduxPayload) => this._createAction(reducerName, payload)
				}
      }
    }
  }
  /*
  *  This method will store the `globalReducers` that will listen that actions no matter the scope
  */

  _setupGlobalReducers(globalReducers = {}) {
    this._globalReducers = globalReducers
  }

  /*
  *  This method will setup the state as properties
  */
  _setupChildren(children) {
    this.children = children

    for (const childName in children) {
      if (children.hasOwnProperty(childName)) {
        assertChildName(this, childName)
        this[childName] = children[childName]
      }
    }
  }

	_createAction(reducerName, payload: ReduxPayload) {
   	return  { type: reducerName, scope: this._scope, payload }
	}

  /*
  *  This method will _call the reducer_ in two different ways
  *  - If it is connected to Redux, will dispatch an action for that reducer
  *  - If not, will apply the reducer directly an store the new state locally
  *
  */

  _callReducer(reducerName, payload: ReduxPayload) {
		const action = this._createAction(reducerName, payload)
    const store = this.constructor._store
    if (store) {
      return store.dispatch(action)
    }
		if(!this._scopedReducers[reducerName]){ // nested reducers object
			reducerName = reducerName.split('_', 2)
			if(this._scopedReducers[reducerName[0]] && this._scopedReducers[reducerName[0]][reducerName[1]]){
				this._state = this._scopedReducers[reducerName[0]][reducerName[1]](this.state, payload)
			}
		}else{
			this._state = this._scopedReducers[reducerName](this.state, payload)
		}
  }


	/*
	*  The `_store` will be the Redux store. Since this store is unique by application, then we
	*  can save _statically_ and use it across all the Reduxable instances.
	*
	*  We use this store internally on two Reduxable instance methods:
	*    - `dispatch` to precisely dispatch the actions
	*    - `state` getter to retrieve the portion of state corredpondent to the Reduxable instance
	*
	*  This method is called from `createStore` method. See its documentation for more details.
	*/

	static _setStore(store) {
		this._store = store
	}

	static _getStore() {
		return this._store
	}

	/*
	*  The `_scope` will define where this Reduxable instance is placed on the global state tree
	*
	*  This scope will be set as a parameter in each dispacthed action and will be used to determine
	*  whether or not an action should be catched by a reducer.
	*
	*  This method is called from `combineReducers` method. See its documentation for more details.
	*/

	_setScope(scope) {
		if(this.stateScope || this.stateScope === false /* root scope */){
			// force scope
			this._scope = this.stateScope
		}else{
			this._scope = scope
		}

		// remove $root: from scope
		if(this._scope){
			this._scope = this._scope.split('.').reduce((_scope, scopeKey) => {
				if(scopeKey.indexOf('$root:') === 0) return _scope; // ignore root state redux
				if(_scope) _scope += '.'
				return _scope + scopeKey
			}, "")
		}

		if(!this._autoReducerScope) this._autoReducerScope = this._scope // TODO: debug

		if (this._scope && this.children) {
			Object.keys(this.children).forEach(key => {
				const child = this.children[key]
				// TODO: bug on autoreducer
				child._setScope && child._setScope(`${scope}.${key}`)
			})
		}
	}


	/**
	 * call at root store to init + add store helper
	 * @param replace
	 * @param normalizedData
	 * @returns {boolean}
	 * @private
	 */
	_initRoot(replace = false){
		const store = this.constructor._store
		if(!store) return false
		// TODO: update data when dev reload
		if(store.reduxable && !replace) throw new Error('Already inited root store')

		store.reduxable = this._normalizeData(!replace)
		initStoreHelper(store)
		// init run
		this.__traverseInit(this, store)
	}

	/**
	 * store meta save through combine/ reduceReducers
	 * @returns {{$root: Reduxable, $tree: {}, $options: {persistConfig: Array, persistCallback: Array, injectDeps: Array}}}
	 * @private
	 */
	__cache_normalizeData = null
	_normalizeData(useCache = true){
		if(useCache && this.__cache_normalizeData) return this.__cache_normalizeData
		let data = {
			$root: this,
			$tree: {},
			$options: {
				persistConfig: [], //Array | boolean, // true = save all in localstorage, [ 'path1.path2', 'path3', ... ]
				persistCallback: [], //(deps: any) => null, // callback once after localstorage rehydrated
				injectDeps: {},// Object, // inject dependency to thunk
			},// merge option
		}

		//
		// traverse all children and create shortcut
		data = this.__traverse(data, this)
		data.$root = this
		this.__cache_normalizeData = data
		return data
	}

	// convert root state to scoped state
	_getStateFromRootState(rootState){
		if (!this._scope) {
			return rootState
		}

		return this._scope.split('.').reduce((object, scopeKey) => {
			return object[scopeKey]
		}, rootState)
	}


	/**
	 * Traverse child node to get all child expose method and option
	 * @param output
	 * @param root
	 * @returns {*}
	 * @private
	 */
	__traverse(output, root:Reduxable) {
		map(root.children, (child, name) => {
			if(child.__isReduxable){
				const methods = child.__filterMethod()
				if(child._scope){
					set(output, "$tree." + child._scope, methods)
					// set(output, "$tree." + child._scope + ".$root", child)
				}else{
					set(output, "$tree." + name , methods)
					// set(output, "$tree." + name + ".$root", child)
				}
				// TODO: warn merge already exist method? -> add log in dev mode
				merge(output, methods)

				// options merge
				if(child.constructor.options){
					const opts = child.constructor.options

					// persistConfig: Array | boolean, // true = save all in localstorage, [ 'path1.path2', 'path3', ... ]
					if(opts.persistConfig){
						let config = []
						let _root = child._scope && child._scope.split('.')[0]
						let _prefix = child._scope && child._scope.split('.').slice(1).join('.')
						if(opts.persistConfig === true){ // persist all
							if(_root){
								if(_prefix){
									config.push([ _root, _prefix ])
								}else{
									config.push([ _root ])
								}
							}
						}else if(Array.isArray(opts.persistConfig)) {
							if(_prefix) _prefix += '.'

							if(!_root){
								config = opts.persistConfig.reduce((sum, config) => {
									if(!config) return sum;
										// calc new root
										const __root = config.split('.')[0]
										const __prefix = config.split('.').slice(1).join('.')
										if(!__root) return sum
										if(__prefix){
											sum.push([ __root, __prefix ])
										}else{
											sum.push([ __root ])
										}
									return sum
								}, [])
							}else{
								config = [ opts.persistConfig.reduce((sum, config) => {
									if(!config) return sum;
										sum.push(_prefix + config)
									return sum
								}, [ _root ])]
							}
						}

						output.$options.persistConfig.push(...config)
					}

					// persistCallback: (deps: any) => null, // callback once after localstorage rehydrated
					if(opts.persistCallback && typeof opts.persistCallback === 'function' ){
						output.$options.persistCallback.push(opts.persistCallback.bind(child))
					}

					// injectDeps: Object, // inject dependency to thunk
					if(opts.injectDeps){
						let injectDeps = opts.injectDeps
						if(typeof opts.injectDeps === 'function') injectDeps = opts.injectDeps()
						output.$options.injectDeps = { ...output.$options.injectDeps, ...injectDeps }
					}

				}

				if(child.children){
					this.__traverse(output, child)
				}
			}
		})
		return output
	}


	/**
	 * Traverse child node to run init
	 * @param root
	 * @returns {*}
	 * @private
	 */
	__traverseInit(root, store) {
		if(root.constructor.options && typeof root.constructor.options.init === 'function'){
			root.constructor.options.init(store)
		}
		if(!root.children) return;
		map(root.children, (child, name) => {
			if(child.__isReduxable){
				this.__traverseInit(child)
			}
		})
	}

	/**
	 * Filter and convert to global usable method
	 * @returns {{}}
	 * @private
	 */
	__filterMethod(actionCreator = true, selector = true) {
		const methods = {}
		// const store = this.constructor._store

		const self = this

		const work = (name, method) => {
			// do__ (action creator)
			// get__ (selector)
			if(actionCreator && name.indexOf('do') === 0){
				methods[name] = method.bind(this)
				methods[name].__orig = method
			}else if(selector && name.indexOf('get') === 0){
				methods[name] = function(rootState, ...args) {
					const state = rootState ? self._getStateFromRootState(rootState) : this.state
					if(rootState === null) rootState = self.rootState
					return method.call(self, state, ...args, { state, args, rootState, self })
				}
				methods[name].__orig = method
			}
		}

		map(this, (method, name) => {
			work(name, method)
		})
		// class method
		//
		const proto = Object.getPrototypeOf(this)
		map(Object.getOwnPropertyNames(proto), name => {
			const method = proto[name]
			work(name, method)
		})
		return methods
	}


}

export default Reduxable
