/* @flow */


type Options = {
	initialState: Object,
	platformDeps?: Object,
	platformMiddleware?: Array<Function>,
};

let _store = null
let _persistor = null
let _middleware = null

let _initialState = {}


// import { isCacheDisabled } from "@lib/base/helper";
import configureMiddleware from './configureMiddleware';
import Redux, { applyMiddleware, createStore, compose } from '@lib/base/redux';
import { autoRehydrate, persistStore } from 'redux-persist';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

// import { persistStore } from 'redux-persist';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
//
// const defaultPersistConfig = {
// 	key: 'root',
// 	storage,
// 	stateReconciler: autoMergeLevel2,
// }

export const _configureStore = (reducer, options: Options) => {// , sync = false) => {
	let {
		initialState,
		platformMiddleware = [],
		platformDeps = {},
		storageSavePaths = [],
		storageCallbacks = [],
	} = options;

	if(reducer._normalizeData){
		// get options from reduxable reducer and pass to storage
		//reduxable
		// platformDeps = {},
		// 	storageSavePaths = [],
		// 	storageCallbacks = [],
		const rdxOptions = reducer._normalizeData().$options
		if(rdxOptions.persistConfig){
			storageSavePaths.push(...rdxOptions.persistConfig)
		}
		if(rdxOptions.persistCallback){
			storageCallbacks.push(...rdxOptions.persistCallback)
		}
		if(rdxOptions.injectDeps){
			platformDeps = { ...platformDeps, ...rdxOptions.injectDeps}
		}
	}

	const { storageConfig, middleware, /*persistConfig*/ } = configureMiddleware(
		initialState,
		platformDeps,
		platformMiddleware,
		storageSavePaths
	);

	let defaultState = (reducer.reduce || reducer)(undefined, {}) // default State
	defaultState = defaultState.merge(initialState, {deep: true})

	_middleware = middleware
	_initialState = defaultState

	_store = createStore(
		reducer,
		defaultState,
		(composeWithDevTools || compose )(
			applyMiddleware(...middleware),
			autoRehydrate(),
		)
	);

	if (process.env.IS_BROWSER) {
		_persistor = persistStore(_store, storageConfig,
			() => {
				// callback
				const deps = { ...platformDeps, getState, dispatch }
				storageConfig.callback && storageConfig.callback(deps)
				storageCallbacks && storageCallbacks.map(storageCallback => storageCallback(deps))
			});

		// if(isCacheDisabled()){ // ?nc => clear persist storge
		// 	// TODO: only purge cache (cache:: prefix)
		// 	// move to configureStorage
		// 	purgeCachePersistStore()
		// }
	}

	// _persistor = persistStore(_store)

	return _store;
};

// -- store function

export const subscribe = (callback) => _store ? _store.subscribe(callback) : null

export const _setStore = (store) => _store = store

export const _getStore = () => _store || null

export const _getInitialState = () => _initialState

export const getState = () => _store ? _store.getState() : null

export const dispatch = (action) => _store ? _store.dispatch(action) : null

// -- middleware
export const getMiddlewares = () => _middleware || {}


// -- persist storage function

export const purgePersistStore = (fields) => _persistor ? _persistor.purge(fields) : null



// export default configureStore;
