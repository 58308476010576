/* @flow */
import { createConst } from 'common/helpers/createConst'

// --- sample const

type ConfigType = {
  BASE_URL: string,
  ASSETS_URL: string,
  ROUTE_BASE_URL: string,
  HTTPS: boolean,
  API: {
    // BASE_URL: 'localhost/paymall/testmall/api',
    BASE_URL: string,
    HTTPS: boolean,
    VERSION: string
  },
  IS_TEST: boolean,
  IS_LOCALHOST: boolean,

  //Version
  VERSION: string,
  REVISION: number,
  BUILD_TIMESTAMP: number,

  //feature
  FEATURES: {
    // CHECKOUT: true,
    // MEGASALE: false,
    // FLASHSALE: false,
  },
}

export default createConst({
    //Config
    //Custom root from php
    BASE_URL: typeof window != 'undefined' && window._BASE_URL ? window._BASE_URL : 'localhost:3000',
    ASSETS_URL: typeof window != 'undefined' && window._ASSETS_URL ? window._ASSETS_URL : '/assets/',
	  _ASSETS_URL:  '/assets/',
    ROUTE_BASE_URL: typeof window != 'undefined' && window._ROUTE_BASE_URL ? window._ROUTE_BASE_URL : '/',
    HTTPS: false,
    API: {
      // BASE_URL: 'localhost/paymall/testmall/api',
      BASE_URL: 'test.api-dummy.com/api',
      HTTPS: true,
      VERSION: 'v1'
    },
    IS_TEST: true,
    IS_LOCALHOST: true,

    //Version
    VERSION: '1.0.0',
    REVISION: 1,
    BUILD_TIMESTAMP:null,

    //feature
    FEATURES: {
      // CHECKOUT: true,
      // MEGASALE: false,
      // FLASHSALE: false,
    },

    SENTRY_URL: "https://6520e5b45fdc4724b058c92ffb799be2@sentry.mixtomax.com/8"

  },

  //auto config by url
  {
    PRODUCTION: {
      BASE_URL: "www.production.com",
      HTTPS:  true,
      ENV: 'production',
      API: {
        BASE_URL: "www.production.com/api",
      }
    },

    TEST: {
      BASE_URL: "www.test.com",
      HTTPS:  true,
      ENV: 'test',
      API: {
        BASE_URL: "www.test.com/api",
      }
    },
  }
)
