
// import Util from "@lnw/util";
import React from 'react'
import { Util } from '@lnw/util';

/*
useMyEffect(this, key, function(props, state, self){
	// run when init for update if key has been changed
	initSomeThing();
}, (props, state, self) => [keyForReload]) // in constructor
*/

export const useMyEffect = (instance, key, effectFunc, keyFunc) => {
	if(!instance.__myEffectInited){
		// init
		instance.__myEffectInited = true
		instance.__effects = []
		instance.__startEffect = effectHelper.__startEffect.bind(instance)
		instance.__registerEffect = effectHelper.__registerEffect.bind(instance)
		instance.__destroyEffect = effectHelper.__destroyEffect.bind(instance)

		// override event
		const oldComponentDidMount = instance.componentDidMount
		instance.componentDidMount = function(){
			instance.__startEffect()
			oldComponentDidMount && oldComponentDidMount.bind(instance)()
		}.bind(instance)

		const oldComponentDidUpdate = instance.componentDidUpdate
		instance.componentDidUpdate = function(prevProps, prevState, snapshot){
			instance.__startEffect()
			oldComponentDidUpdate && oldComponentDidUpdate.bind(instance)(prevProps, prevState, snapshot)
		}.bind(instance)

		const oldComponentWillUnmount = instance.componentWillUnmount
		instance.componentWillUnmount = function(){
			instance.__destroyEffect()
			oldComponentWillUnmount && oldComponentWillUnmount.bind(instance)()
		}.bind(instance)
	}
	// if(useMyEffect.callee.caller.name == 'render'){
	// 	// can't use in render function
	// 	// TODO: chain function (custom hook)
	// 	return;
	// }
	instance.__registerEffect(key, effectFunc, keyFunc)
}


// const state = useState(initialState);
// state.get state.set
export const useMyState = (instance, key, initialState = null) => {
	const setState = (value) => instance.setState({ [key]: value })
	const getState = () => instance.state[key] || initialState
	if(!instance.state) instance.state = {}
	if(!instance.state[key]) instance.state[key] = initialState
	return {
		// get value () {
		// 	return getState()
		// },
		// set value (v) {
		// 	setState(v)
		// },
		key,
		get: getState,
		set: setState,
		valueOf: getState,
		toString: getState
	}
}

export type myStateGetHelperType = {
	stateObjects: any,
	setState: (k:string, v:any) => boolean,
	getState: () => any,
	func: any,
}

export const myStateGetHelper = (state) : myStateGetHelperType => ({
	stateObjects: state,
	setState:(k, value) => {
		if(!state[k]) return false;
		state[k].set(value);
		return true;
	},
	getState: () => {
		const ret = {}
		Util.map(state, (v,k) => {
			ret[k] = v.get()
		})
		return ret
	}
})

// // const [state, setState] = useState(initialState);
// export const useMyState = (instance, key, initialState = null) => {
// 	const setState = (value) => instance.setState({ [key]: value })
// 	const getState = (value) => instance.state[key] || initialState
// 	if(!instance.state) instance.state = {}
// 	if(!instance.state[key]) instance.state[key] = initialState
// 	return [ instance.state[key] || initialState , setState, getState ]
// }



//const [ state, dispatch ] = useReducer(this, reducer, initialState); // in constructor
//const [ state, dispatch ] = useReducer(this); // in render function
// export const useMyReducer = (instance, reducer = null, initialState = {}) => {
//  //TODO:
// }

const effectHelper = {
	__registerEffect: function(key, effectFunc, keyFunc: (props, state) => any){
		this.__effects[key] = {
			effectFunc,
			keyFunc,
			latestKey: null,
			destroyFunc: null
		}
	},
	__startEffect: function(){
		Util.map(this.__effects, (effect) => {
			// key check = need update?
			if(effect.keyFunc){
				const newKey = JSON.stringify(effect.keyFunc.bind(this)(this.props, this.state, this))
				if(newKey === effect.latestKey ) return;
				effect.latestKey = newKey
			}else{
				// no key -> only run once?
				if(effect.latestKey !== null) return;
				effect.latestKey = true
			}

			// destructor
			if(effect.destroyFunc) {
				effect.destroyFunc.bind(this)(this.props, this.state, this)
				effect.destroyFunc = null
			}

			//start effect
			const ret = effect.effectFunc.bind(this)(this.props, this.state, this)
			if(typeof ret == 'function') {
				effect.destroyFunc = ret
			}

		})
	},
	__destroyEffect: function(){
		Util.map(this.__effects, (effect) => {

			// destructor
			if(effect.destroyFunc) {
				effect.destroyFunc.bind(this)(this.props, this.state, this)
				effect.destroyFunc = null
				effect.latestKey = null
			}

		})
	}

}