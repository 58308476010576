/* eslint-disable */
// import { _L, _l } from 'common/helpers'
// import messages from './___messages'
// import { defineMessages } from 'react-intl';

export default /*defineMessages*/({
	save: {
		id: 'save',
		defaultMessage: 'บันทึก'
	},
	notFound: {
		id: 'notFound',
		defaultMessage: 'Not found'
	},
});

