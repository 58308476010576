import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Reduxable from '@lib/reduxable'

/**
 * ex: connect([ 'selectCart', [ 'selectAction',{ props1 }, 'customOutPutName' ], 'doAddToCart' ], state => ({ test: state.test}), { action1, action2 })(Component)
 * connect(state => ({ test: state.test}), { action1, action2 })(Component)
 * @param reduxableArr
 * @param mapStateToProps
 * @param mapDispatchToProps
 * @param mergeProps
 * @param options
 * @returns {*}
 */

export default function (reduxableArr, mapStateToProps, mapDispatchToProps, mergeProps, options) {
	if(!Array.isArray(reduxableArr) || reduxableArr.length === 0){
		// normal
		return connect.apply(this, arguments)
	}
	// param
	//Reduxable._getStore()
	const _mapStateToProps = mapStateToProps
	mapStateToProps = function (state, ownProp) {
		return {
			...(_mapStateToProps && _mapStateToProps(state, ownProp)),
			...Reduxable._getStore().selects(reduxableArr, state)
		}
	}

	const _mapDispatchToProps = mapDispatchToProps
	mapDispatchToProps = function (dispatch, ownProp) {
		let map = null
		if(typeof _mapDispatchToProps === 'function') map = _mapDispatchToProps(dispatch, ownProp)
		else if(_mapDispatchToProps) map = bindActionCreators(_mapDispatchToProps,dispatch)
		return {
			...map,
			...Reduxable._getStore().actions(reduxableArr)
		}
	}

	// TODO: hook to get store from context instead of global from Reduxable
	return connect.apply(this, [ mapStateToProps, mapDispatchToProps, mergeProps, options] )
}