import _ from 'common/helpers/_'
import { getState as _getState } from 'common/configure/configureStore'

export const mapGetters = (state, getters, keys) => {
	if(!state) return {}
	let props = {}
	keys.forEach(v => {
		// if(typeof v == 'function') {
		//   props = { ...props, ...v(state) }
		// }else{
		// props[v] = typeof getters[v] == 'function' ? getters[v](state) : undefined
		let getter = _.get(getters, v, undefined)
		props[v] = typeof getter == 'function' ? getter(state) : undefined
		// }
	})
	return props
}

export const mapAllGetters = (state, getters) => {
	if(!state) return {}
	if(!getters) return {};
	let props = {}
	let keys = Object.keys(getters);
	keys.forEach(v => {
		let getter = _.get(getters, v, undefined)
		props[v] = typeof getter == 'function' ? getter(state) : undefined
		if(typeof getter == 'function'){
			props[v] = getter(state) || undefined
		}else if(typeof getter == 'object'){
			props[v] = mapAllGetters(state, getter)
		}
	})
	return props
}

export const mapActions = (actions, keys) => {
	let props = {}
	keys.forEach(v => {
		// if(typeof v == 'function') {
		//   props = { ...props, ...v(state) }
		// }else{
		props[v] = typeof actions[v] == 'function' ? actions[v] : undefined
		// }
	})
	return props
}

export const getState = _getState

export const commit = (dispatch, type, payload = null) => dispatch({ type, payload })

export const _stateCreator = (getModuleState) => (state = null, edit = true) => {
	if(!state) state = _getState()
	return edit ? getModuleState(state).asMutable({ deep: true }) : getModuleState(state)
}

export const _stateUpdateCreator = (getModuleName) => (oldState, newState) => {
	const _newState = {}
	_newState[getModuleName()] = newState
	return oldState.merge(_newState, { deep: true })
}