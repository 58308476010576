/* @flow weak */
// import { Map, List } from '/common/immutable';
// import {} from 'recruitment/common/helpers'
// --- [ACTION_IMPORT]
// --- [/ACTION_IMPORT]
import axios from 'axios';

const version = 'manage_v2/';

export {
// --- [ACTION_EXPORT]
// --- [/ACTION_EXPORT]
}

// --- [ACTION_CONST]
export const FETCH_MANAGER = 'RECRUITMENT_FETCH_MANAGER';
export const CLEAR_MANAGER = 'RECRUITMENT_CLEAR_MANAGER';
export const LOGIN = 'RECRUITMENT_LOGIN_MANAGER';
export const SORT_APPLICANT = 'RECRUITMENT_SORT_APPLICANT';
export const UPDATE_APPLICANT = 'RECRUITMENT_UPDATE_APPLICANT'
export const UPDATE_CONTACT = 'RECRUITMENT_UPDATE_CONTACT';//mike add
export const CHANGE_APPOINTMENT = 'RECRUITMENT_CHANGE_APPOINTMENT';
export const UPDATE_APPOINTMENT = 'RECRUITMENT_UPDATE_APPOINTMENT';
export const REMOVE_APPOINTMENT = 'RECRUITMENT_REMOVE_APPOINTMENT';
export const CONFIRM_RESULT_APPOINTMENT = 'CONFIRM_RESULT_APPOINTMENT';
export const ANNOUNCE_UPDATE = 'ANNOUNCE_UPDATE';
export const DEPARTMENT_UPDATE = 'DEPARTMENT_UPDATE';
// --- [/ACTION_CONST]


// --- [ACTION_CREATORS]
export const fetchManager = () => ({ getState, dispatch }) => {
	const { manager } = getState().recruitment
	// console.log(manager)
	if (manager.login) {
		dispatch({ type: FETCH_MANAGER, payload: null })
		let data = new FormData()
		data.append('google_id', manager.login.google_id)
		data.append('fullname', manager.login.fullname)
		data.append('nickname', manager.login.nickname)
		data.append('e_mail', manager.login.e_mail)

		axios.post(version + 'login.php', data).then((response) => {
			dispatch({ type: FETCH_MANAGER + '_SUCCESS', payload: response.data })
		}).catch((error) => {
			console.log(error)
			dispatch({ type: FETCH_MANAGER + '_ERROR', payload: error.response.data })
		})
	}
}

export function sortApplicant(applicant, order) {
	let data = applicant.asMutable()
	return {
		type: SORT_APPLICANT, payload: data.sort((a, b) => {
			if (a[ order.type ] > b[ order.type ]) {
				return order.reverse ? -1 : 1
			}
			if (a[ order.type ] < b[ order.type ]) {
				return order.reverse ? 1 : -1
			}
			return 0
		})
	}
}

function askReloadData(){
  if(confirm('มีข้อมูลบางแถวเปลี่ยนกลุ่มต้องการ reload หรือไม่')){
    window.location.reload();
  }
}

export function updateApplicant(index, field, value) {
	return {
		type: UPDATE_APPLICANT,
		payload: { index, field, value }
	}
}

export function updateContact(index, field, group,code,value) {
  return {
    type: UPDATE_CONTACT,
    payload: { index, field, group,code,value }
  }
}

export function login(id, profile = null, id_token = null) {
	return {
		type: LOGIN,
		payload: {
			google_id: id,
			fullname: profile.getName(),
			nickname: profile.getGivenName(),
			e_mail: profile.getEmail(),
      id_token: id_token
		}
	}
}

export function logout() {
	return {
		type: CLEAR_MANAGER,
		payload: null
	}
}

export const changeAppointment = (code, key, date) => {
	return {
		type: CHANGE_APPOINTMENT,
		payload: { code, key, date }
	}
}

export const updateAppointment = (managerId, code, data_row, date) => {
	let data = new FormData()
	data.append('manager_id', managerId)
	data.append('code', code)
	data.append('data_row', data_row)
	data.append('date', date)
	return () => {
		axios.post(version + 'appointment.php', data)
			.then(() => {
				console.log(code, data_row, date)
			})
			.catch((error) => {
				console.log(error)
				alert(error.response.data)
			})
	}
}

export function updateField(managerId, applicant, field, update) {
	console.log(managerId, applicant, field, update)
	let data = new FormData()
	data.append('manager_id', managerId)
	data.append('position_code', applicant.position)
	data.append('data_row', applicant.row)
	data.append('field', field)
	data.append('update', update)
	return () => {
		axios.post(version + 'update.php', data).then((response) => {
			console.log(field, update)
      //*** HARD TO REUSE login.php :/ then reload instead
      if (field == 'result'){
        askReloadData();
      }
      // if (response && response.data && response.data.department_change){
      //   //move person to another group in department (payload.code , .value)
      //   dispatch({ type: DEPARTMENT_UPDATE, payload: response.data.department_change })
      // }
		}).catch((error) => {
			console.log(error)
			alert(error.response.data)
		})
	}
}



export const updateOpinion = (managerId, applicant, opinion, interview) => {
	let data = new FormData()
	data.append('manager_id', managerId)
	data.append('position_code', applicant.position)
	data.append('data_row', applicant.row)
	data.append('opinion', opinion)
	data.append('interview', interview)
	data.append('interview_name', applicant.fullname + " (" + applicant.nickname + ")")
	let {appointment} = applicant
	if(appointment && appointment <= Date.now()){
		data.append('after_interview', true)
	}
	return () => {
		axios.post(version + 'opinion.php', data).then(() => {
			console.log(opinion, interview)
		}).catch((error) => {
			console.log(error)
			alert(error.response.data)
		})
	}
}

export const sendResult = (managerId,applicant,result,memo)=>{
	let data = new FormData()
	data.append('manager_id',managerId)
	data.append('position_code',applicant.position)
	data.append('data_row',applicant.row)
	if(result){
		data.append('result',"รับเข้าทำงาน")
		}else{
		data.append('result',"ไม่รับเข้าทำงาน")
	}
	data.append('memo',memo)
	return ()=>{
		axios.post(version+'result.php',data).then(()=>{
			console.log(result,memo)
			}).catch((error)=>{
				console.error(error)
				})
	}
}

export const updateResult = (managerId, decision) => {
	let data = new FormData()
	data.append('manager_id', managerId)
	data.append('decision', decision)
	return () => {
		axios.post(version + 'decision.php', data).then((respone) => {
			console.log(decision)
			alert(respone.data)
		}).catch((error) => {
			console.log(error)
			alert(error.response.data)
		})
	}
}

export const updateInterview = (managerId, position, opinion) => {
	let data = new FormData()
	data.append('manager_id', managerId)
	data.append('position_code', position.code)
	data.append('data_row', position.row)
	data.append('opinion', opinion)
	return () => {
		axios.post(version + 'interview.php', data).then(() => {
			console.log(opinion)
		}).catch((error) => {
			console.log(error)
			alert(error.response.data)
		})
	}
}

export const refuseInterview = (managerId, code, data_row, store_remove = true) => {
	let data = new FormData()
	data.append('manager_id', managerId)
	data.append('position_code', code)
	data.append('data_row', data_row)
	return (d) => {
		axios.post(version + 'refuse.php', data).then(() => {
			console.log(code, data_row)
			if (store_remove) {
				d.dispatch({ type: REMOVE_APPOINTMENT, payload: { code, data_row } })
			}
		}).catch((error) => {
			console.log(error)
			alert(error.response.data)
		})
	}
}
export const confirmResult = (managerId, code, data_row,confirming) => { //, store_remove = true
  let data = new FormData()
  data.append('manager_id', managerId)
  data.append('position_code', code)
  data.append('data_row', data_row)
  data.append('type', confirming);
  return (d) => {
    axios.post(version + 'confirm_result.php', data).then((response) => {
      console.log(code, data_row)
      if (response){
        askReloadData()
      }
      // if (store_remove) {
      //   d.dispatch({ type: CONFIRM_RESULT_APPOINTMENT, payload: { code, data_row } })
      // }
    }).catch((error) => {
      console.log(error)
      alert(error.response.data)
    })
  }
}

export const absentInterview = (managerId, code, data_row, store_remove = true) => {
	let data = new FormData()
	data.append('manager_id', managerId)
	data.append('position_code', code)
	data.append('data_row', data_row)
	return (d) => {
		axios.post(version + 'absent.php', data).then(() => {
			console.log(code, data_row)
			if (store_remove) {
				d.dispatch({ type: REMOVE_APPOINTMENT, payload: { code, data_row } })
			}
		}).catch((error) => {
			console.log(error)
			alert(error.response.data)
		})
	}
}
export const updateAnnouce = (code, data_row, type) => ({ getState, dispatch }) => {
// export const updateAnnouce = (managerId, code, data_row, type) => {
  const { manager } = getState().recruitment
  dispatch({ type: ANNOUNCE_UPDATE, payload: null })
	let data = new FormData()
	data.append('manager_id', manager.login.google_id)//managerId)
	data.append('position_code', code)
	data.append('data_row', data_row)
	data.append('type', type)
	// return () => {
		axios.post(version + 'annouce.php', data).then((response) => {
      dispatch({ type: ANNOUNCE_UPDATE + '_SUCCESS', payload: response.data })
    }).catch((error) => {
      console.log(error)
      dispatch({ type: ANNOUNCE_UPDATE + '_ERROR', payload: error.response.data })
		})
	// }
}
// --- [/ACTION_CREATORS]
