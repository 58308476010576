/* @flow */
import React from 'react';
import util from '@lnw/util'
// import { Base } from 'rebass';
import { pure } from 'recompose'

// TODO: View common component

const styleShortHand = {
	h: "height",
	w: "width",
	m: "margin",
	mt: "marginTop",
	mr: "marginRight",
	mb: "marginBottom",
	ml: "marginLeft",
	mx: ["marginLeft", "marginRight"],
	my: ["marginTop", "marginBottom"],
	p: "padding",
	pt: "paddingTop",
	pr: "paddingRight",
	pb: "paddingBottom",
	pl: "paddingLeft",
	align: "textAlign",
	px: ["paddingLeft", "paddingRight"],
	py: ["paddingTop", "paddingBottom"],
}

export const View = pure((props: Object) => {

	const style = props.style || {}
	const _props = { ...props, style }

	Object.keys(styleShortHand).map(k => {
		if (props[k]) {
			const styleFields = !util.isArray(styleShortHand[k]) ? [styleShortHand[k]] : styleShortHand[k]
			styleFields.map(styleField => style[styleField] = util.isNumeric(props[k]) ? (props[k] + "px") : props[k])
			delete _props[k]
		}
	})

	const Component = props.is || 'div'
	delete _props.is

	return (
		<Component {..._props}/>
	);
})
//<Base {...props} is="div" />
export default View;
