import Reduxable from './Reduxable'
export * from './Reduxable'

import createStore from './createStore'
import combineReducers from './combineReducers'
import reduceReducers from './reduceReducers'

import connect from './connect'

export default Reduxable
export { createStore, combineReducers, reduceReducers, connect }
