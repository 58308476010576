/* @flow weak */
import { REHYDRATE } from 'redux-persist/constants';
import configureStorage from './configureStorage';
import errorToMessage from '../../app/messages/errorToMessage';
import { __setPlatformDeps } from '../../helpers/platformDeps';
// import { stateTransformer } from 'redux-seamless-immutable'
// Middleware
import { apiMiddleware } from '@lnw/api/apiMiddleware';
import { bufferActions, isBufferActive } from '@lib/base/redux-action-buffer';

// import { FIREBASE_ON_AUTH, FIREBASE_START } from '@lib/redux-firebase/actions';

// Deps.
import validate from 'common/validate';


import modulesInjectDeps from '_config/common/injectDeps';

// const firebaseDeps = null;

// Like redux-thunk but with dependency injection.
const injectMiddleware = deps => ({ dispatch, getState }) => next => action =>
	next(typeof action === 'function'
		? action({ ...deps, dispatch, getState })
		: action
	);

// callback = run after buffer
const callbackMiddleware = deps => ({ dispatch, getState }) => next => action =>
	next(action && typeof action.callback === 'function'
		? action.callback({ ...deps, dispatch, getState })
		: action
	);

// Like redux-promise-middleware but simpler.
const promiseMiddleware = options => ({ dispatch, getState }) => next => action => {
	// Not do anything if buffer active
	// if (isBufferActive()) return next(action);

	const { shouldThrow } = options || {};
	const { payload } = action;
	const payloadIsPromise = payload && typeof payload.then === 'function';
	if (!payloadIsPromise) return next(action);

	const createAction = (suffix, payload) => ({
		type: `${action.type}_${suffix}`, meta: { action }, payload,
	});

	// skip function -> check state if there a need to call api
	const hasSkipFunction = payload && typeof payload.skip === 'function';

	if (hasSkipFunction) {
		if (payload.skip(getState()) === true) {
			if (typeof payload.cancel === 'function') payload.cancel()
			return next(createAction('SKIP'));
		}
	}

	// Note we don't return promise.
	// github.com/este/este/issues/1091
	payload
		.then(value => dispatch(createAction('SUCCESS', value)))
		.catch(error => {
			dispatch(createAction('ERROR', error));
			// Not all errors need to be reported.
			if (shouldThrow(error)) {
				throw error;
			}
		});
	return next(createAction('START'));

	// https://github.com/pburtchaell/redux-promise-middleware/blob/master/src/index.js
	// return promise?
	// return payload
};


//remote debugger
const isDebuggingEnabled = (typeof atob !== 'undefined') || (typeof DedicatedWorkerGlobalScope) !== 'undefined'

const enableLogger = (process.env.NODE_ENV !== 'production' && (
	process.env.IS_BROWSER || isDebuggingEnabled // || !initialState.device.isReactNative
)) || (typeof localStorage !== 'undefined' && (localStorage.getItem('enableLogger') === '1' || !!localStorage.debug));

//TODO: change to import
import createLoggerMiddleware from 'redux-logger';


const configureMiddleware = (initialState, platformDeps, platformMiddleware, storageSavePaths) => {
	// Lazy init.
	// TODO: firebase
	// if (!firebaseDeps && initialState.config.firebase) {
	//
	//   const firebase = require('firebase');
	//   firebase.initializeApp(initialState.config.firebase);
	//   firebaseDeps = {
	//     firebase: firebase.database().ref(),
	//     firebaseAuth: firebase.auth,
	//     firebaseDatabase: firebase.database,
	//   };
	// }


	// Check whether Firebase works.
	// firebaseDeps.firebase.child('hello-world').set({
	//   createdAt: firebaseDeps.firebaseDatabase.ServerValue.TIMESTAMP,
	//   text: 'Yes!'
	// });

	const storageConfig = configureStorage(initialState, platformDeps.storageEngine, storageSavePaths);

	__setPlatformDeps(platformDeps)

	// buffer action for dynamic reducer
	// dynamicReducerPrefixes
	const bufferDynamicReducer = []
	const { dynamicReducerPrefixes } = require('_config/common')
	if(dynamicReducerPrefixes){
		dynamicReducerPrefixes.map(prefix => {
			prefix = prefix.toUpperCase()
			bufferDynamicReducer.push(bufferActions(
				// breaker
				action => action.type === 'RELOAD_REDUCER' && action.payload &&
					( action.payload.name.toUpperCase().trimChar('_') === prefix.trimChar('_')),
				//ignore
				actionIgnore => actionIgnore.type.indexOf(prefix) === -1

			))
		})
	}

	const { subscribe } = require('../configureStore');
	const { APP_SET_LOCATION, APP_START } = require('common/app/actions');

	const deps = {
		// ---- Dependencies
		...platformDeps,
		// ...firebaseDeps,
		storageConfig,
		validate,
		subscribe, // store.subscribe
		getUid: () => platformDeps.uuid.v4(),
		now: () => Date.now(),
		...modulesInjectDeps,
	}

	const middleware = [
		injectMiddleware(deps),
		// buffer action for dynamic reducer
		...bufferDynamicReducer,
		// Action buffer - wait until REHYDRATE finish before doing other action
		...(process.env.IS_BROWSER ? [bufferActions(REHYDRATE, [
			// middleware.push(bufferActions(REHYDRATE, [
			// -- Bypass action buffer
			APP_START,
			APP_SET_LOCATION,
			// FIREBASE_START,
			// FIREBASE_ON_AUTH,
			'RELOAD_REDUCER'
		])] : []),
		callbackMiddleware(deps),
		apiMiddleware({
			shouldThrow: error => !errorToMessage(error),
		}),
		promiseMiddleware({
			shouldThrow: error => !errorToMessage(error),
		}),
		...platformMiddleware,
	];


	// const enableLogger = false
	// const enableLogger = true
	// console.log('logger')

	// Logger must be the last middleware in chain.

	if (enableLogger) {
		const ignoredActions = [];// REHYDRATE];
		const logger = createLoggerMiddleware({
			predicate: (getState, action) => ignoredActions.indexOf(action.type) === -1 && !(action.payload && action.payload.logDisable),
			// TODO: diff too slow?
			// diff: typeof navigator != 'undefined' && navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ?
			//   false : true, // disable diff in firefox
			diff: typeof localStorage === 'undefined' ? false : !!localStorage.debug,
			//Collapse actions that don't have errors
			collapsed: (getState, action, logEntry) => !logEntry.error,
			// collapsed: true,
			// Convert immutable to JSON.
			// stateTransformer: state => JSON.parse(JSON.stringify(state)),
			actionTransformer: action => {
				if(action && action.payload && action.payload.request) {
					//console.log('request', action.payload.request)
					action.payload.request = null
				}
				return action
			},
			stateTransformer: state => typeof state.asMutable === 'function' ? state.asMutable({ deep: true }) : state
		});
		middleware.push(logger);
	}

	return { storageConfig, middleware };
};

export default configureMiddleware;
