
import lowerFirst from 'lodash/lowerFirst'
import get from 'lodash/get'
import map from 'lodash/map'



export default (store) => {
	if(!store.reduxable) throw new Error('init with reduxable first')
	// TODO: more helper
	// https://reduxbundler.com/api/store.html
	// helper

	// selects([ 'getCart', ['getCustomWithArgs',[ arg1, arg2 ], 'customOutPutName' ]])
	store.selects = (selectorNames, rootState = null) => {
		if(selectorNames.length === 1 && selectorNames[0] === 'selectAll'){
			return { "all" : store.selectAll(rootState) }
		}
		const out = {}
		selectorNames.map(selectorName => {
			let args = []
			let outputName = null
			if(Array.isArray(selectorName)){
				// ['getCustomWithArgs',[ arg1, arg2 ], 'customOutPutName' ]
				// getCustomWithArgs(state, arg1, arg2) => ...
				if(selectorName.length > 3){
					console.error('selector', selectorName, 'error')
				}else if(selectorName.length == 3 ) {
					args = selectorName[1]
					outputName = selectorName[2]
					selectorName = selectorName[0]
				}else if(selectorName.length == 2 ) {
					outputName = selectorName[1]
					selectorName = selectorName[0]
				}else{
					selectorName = selectorName[0]
				}
			}
			const selector = selectorName.indexOf('.') === -1 ? get(store.reduxable, selectorName) : get(store.reduxable, '$tree.' + selectorName)
			if(selector){
				selectorName = selectorName.split(".")
				selectorName = selectorName[selectorName.length-1]
				if(selectorName.indexOf("get") === 0){
					selectorName = lowerFirst(selectorName.replace("get",""))
					if(!outputName) outputName = selectorName
					out[outputName] = selector(rootState, ...args)
					// console.log(outputName, selectorName, out[outputName])
				}
			}else{
				if(selectorName && selectorName.indexOf('do') !== -1 ) console.warn('selector ', selectorName , 'not found')
			}
		})
		return out
	}

	store.selectAll = (rootState = null) => {
		const out = {}
		map(store.reduxable, (selector, selectorName) => {
			if(selectorName.indexOf("get") === 0){
				selectorName = lowerFirst(selectorName.replace("get",""))
				out[selectorName] = selector(rootState)
			}
		})
		return out
	}

	const _dispatch = store.dispatch
	store.dispatch = function (action) {
		if (action === true || action === false) return action
		return _dispatch(action)
	}

	// get actions
	// actions([ 'doAction', ['doAction', 'customName' ]])
	store.actions = (actionNames) => {
		const out = {}
		actionNames.map(actionName => {
			let outputName = null
			if(Array.isArray(actionName)){
				outputName = actionName[1]
				actionName = actionName[0]
			}
			const action = get(store.reduxable, actionName)
			if(action){
				actionName = actionName.split(".")
				actionName = actionName[actionName.length-1]
				if(actionName.indexOf("do") === 0){
					if(!outputName) outputName = actionName
					out[outputName] = function () { return store.dispatch(action(...arguments)) }
				}
			}else{
				if(actionName && actionName.indexOf('get') !== -1 ) console.warn('action ', actionName , 'not found')
			}
		})
		return out
	}

	// call single action
	store.action = (actionName, ...args) => {
		const action = get(store.reduxable, actionName)
		if(!action) return
		if(action){
			actionName = actionName.split(".")
			actionName = actionName[actionName.length-1]
			if(actionName.indexOf("do") === 0){
				return store.dispatch(action(...(args || [])))
			}
		}
	}
	// TODO: https://reduxbundler.com/api/store.html
	// TODO: subscribeToSelectors

}