/* @flow */

// TODO: debug fix queue randomly stop -> revert code

import { Util } from '@lnw/util';
import { getUrl } from './url';
import { Map } from 'common/immutable';
import { isServer } from "@lib/base/common";
import _ from 'common/helpers/_';

const constCache = {}

if (typeof global != 'undefined')
	global.__REACT__CONST = constCache

const trimChar = function (str, charlist) {
	if(!str || typeof str !== 'string') return str
	if (charlist === undefined) {
		charlist = '\s';
	}
	return str.replace(new RegExp(`^[${charlist}]+`), '').replace(new RegExp(`[${charlist}]+$`), '');
};

const fixBaseUrl = (config, url = null) => {
	// remove http / https and change to config.HTTPS variable
	if (config && config.BASE_URL && config.BASE_URL.test && url) {
		const ret = config.BASE_URL.exec(url)
		if(ret !== null){
			config.BASE_URL = config.BASE_URL.exec(url)[0]
		}
	}
	if (config && typeof config.BASE_URL == 'string') {
		const arr = config.BASE_URL.split('://')
		if (arr.length >= 1) {
			if(!config.HTTPS && arr[0].length > 3)
				config.HTTPS = arr[0].toLowerCase() == 'https'
			config.BASE_URL = config.BASE_URL.replace(arr[0] + '://', '')
		}
	}
	return config
}

export const createConst: ConfigType = (baseConst: ConfigType, configs: Array<ConfigType>, configsByEnv: Array<ConfigType>) =>
	(url, CONST_TO_ASSIGN = null) => {
		url = url || getUrl()
		// console.trace('createConst', url)
		let currentConfig = null

		if (url && configs) {
			Util.map(configs, (config: ConfigType, configName) => {
				if(currentConfig) return;
				if(config.BASE_URL){
					if(config.BASE_URL.test){
						// regex
						if(config.BASE_URL.test(url)){
							currentConfig = configName
							return false;
						}
					}else{
						// string
						const indexOf = url.indexOf(config.BASE_URL)
						if (config.BASE_URL && indexOf != -1 && indexOf <= 9) { // https://
							currentConfig = configName
							return false;
						}
					}

				}

				// special condition (node server mode)
				if(typeof config.CONDITION == "function"){
					if(config.CONDITION(global.env || (process && process.env), url)){
						currentConfig = configName
						return false;
					}
				}
			})
		}
		if (currentConfig) {
			if(constCache[currentConfig] && constCache[currentConfig].CALLBACK) constCache[currentConfig].CALLBACK()
			if (constCache[currentConfig] && typeof constCache[currentConfig].process !== 'function'){
				return constCache[currentConfig];
			}

			constCache[currentConfig] = _.merge({
				CURRENT_CONFIG: currentConfig,
			}, baseConst, configs[currentConfig])
		} else {
			if (constCache._) return constCache._;
			currentConfig = '_' // default
			constCache[currentConfig] = _.merge({
				CURRENT_CONFIG: 'default',
			}, baseConst)
		}

		// merge with configsByEnv
		if (configsByEnv && constCache[currentConfig].ENV){
			let envs = constCache[currentConfig].ENV;
			if(!Array.isArray(envs)) envs = [envs]
			envs.map(env => {
				if(configsByEnv[env]) {
					constCache[currentConfig] = _.merge(constCache[currentConfig],
						configsByEnv[env])
				}
			})
		}


		// force to merge with specific config
		const forceConfig = (typeof window != 'undefined' && window._FORCE_CONFIG_NAME) || process.env.FORCE_CONFIG_NAME
		if (forceConfig && configs[forceConfig]) {
			constCache[currentConfig] = _.merge(constCache[currentConfig],
				configs[forceConfig])
		}

		// fix base url
		constCache[currentConfig] = fixBaseUrl(constCache[currentConfig], url);
		if (constCache[currentConfig].API && constCache[currentConfig].API.BASE_URL) {
			constCache[currentConfig].API = fixBaseUrl(constCache[currentConfig].API, url);
		}


		// process function
		if(typeof constCache[currentConfig].process === 'function'){
			// process = (currentConst, env ,url) => newConst
			try{
				let tmp = constCache[currentConfig].process(constCache[currentConfig], global.env || (process && process.env), url)
				if(tmp){
					constCache[currentConfig] = tmp
					if(!constCache[currentConfig]){
						console.error('config process error = return empty', currentConfig, global.env || (process && process.env), url)
					}
				}
			}catch(e){
				console.error('config process error', e, currentConfig, global.env || (process && process.env), url)
			}
		}

		const constApiLoaded = (typeof window != 'undefined' && window._CONST)

		// merge const from server
		// TODO: merge before or after process?
		if(constApiLoaded) {
			constCache[currentConfig] = _.merge(constCache[currentConfig], window._CONST)
		}

		// fix base url
		//BASE_URL: "localhost:3000/web/ton/preview/"
		// ASSETS_URL: "/"
		// API: BASE_URL: "localhost/lnwshop/ton/apix"
		// LnwAccount: BASE_BYPASS_LOGIN_URL: "poom.lnwshop.com/apix"

		let _CONST = constCache[currentConfig] || {}
		if(_CONST.BASE_URL) _CONST.BASE_URL = trimChar(_CONST.BASE_URL,'/') + '/'
		if(_CONST.ASSETS_URL) _CONST.ASSETS_URL = trimChar(_CONST.ASSETS_URL, '/') + '/'
		if(_CONST.API && _CONST.API.BASE_URL) _CONST.API.BASE_URL = trimChar(_CONST.API.BASE_URL, '/')
		if(_CONST.LnwAccount && _CONST.LnwAccount.BASE_BYPASS_LOGIN_URL)
			_CONST.LnwAccount.BASE_BYPASS_LOGIN_URL = trimChar(_CONST.LnwAccount.BASE_BYPASS_LOGIN_URL, '/')
		_CONST.uuid = url

		constCache[currentConfig]= _CONST

		if(CONST_TO_ASSIGN !== null){
			if(CONST_TO_ASSIGN.asMutable){
				console.warn("can't update const.")
			}else{
				// update to current const without create new one
				Object.keys(CONST_TO_ASSIGN).forEach(function(key) { delete CONST_TO_ASSIGN[key]; });
				_.merge(CONST_TO_ASSIGN, constCache[currentConfig])
				// TODO: bug when merge deep (LnwAccount)
				constCache[currentConfig] = CONST_TO_ASSIGN
			}
		}

		//read only
		if(!isServer() // server need to change const per page
			&& constApiLoaded // or already load CONST api
			&& !constCache[currentConfig].asMutable
		) {
			constCache[currentConfig] = Map(constCache[currentConfig]);
		}


		return constCache[currentConfig];
	}
