
export const getMainModuleName = () => require('./_').default.camelCase(process.env.MAIN_MODULE)


export const isLocalhost = () => {
	// fix server const path
	// return require('_config/common/const').default.IS_LOCALHOST
	return require('@lib/base/const').default.IS_LOCALHOST
}

export const isTest = () => {
	// fix server const path
	// return require('_config/common/const').default.IS_TEST
	return require('@lib/base/const').default.IS_TEST
}


export const isCacheDisabled = () => {
	if(process.env.CACHE_DISABLE) return true
	if(global.CACHE_DISABLE) return true
	if(typeof window !== 'undefined'){
		if(window.CACHE_DISABLE) return true
		if(typeof window.CACHE_DISABLE === 'undefined'){
			//check param
			const params = require('@lnw/util/util').default.urlParams()
			if(params.nc){ // ?nc = global CACHE_DISABLE
				window.CACHE_DISABLE = true
				global.CACHE_DISABLE = true
				return true
			}
		}
		if(typeof sessionStorage !== 'undefined' && !isLocalhost()){
			if(sessionStorage.getItem('__CACHE_DISABLE')){ // disable all cache for owner
				window.CACHE_DISABLE = true
				global.CACHE_DISABLE = true
				return true
			}
		}
		window.CACHE_DISABLE = false
		global.CACHE_DISABLE = false
	}
	return false
}

export const setCacheDisabledSession = (disable = true) => {
	if(typeof sessionStorage !== 'undefined' && !isLocalhost()){
		const { apiCacheDisable } = require("@lnw/api/api");
		if(disable){
			sessionStorage.setItem('__CACHE_DISABLE', '1')
			window.CACHE_DISABLE = true
			global.CACHE_DISABLE = true
			apiCacheDisable(true)
		}else{
			sessionStorage.removeItem('__CACHE_DISABLE')
			window.CACHE_DISABLE = false
			global.CACHE_DISABLE = false
			apiCacheDisable(false)
		}
	}else{
		global.debug('setCacheDisabledSession', disable)
	}
}