import { createStore } from 'redux'

import Reduxable from './Reduxable'

/**
 * Creates a Redux store that holds the state tree.
 *
 * The only difference with Redux's `createStore` is that it also recognizes
 * the Reduxable instances getting its reducers.
 *
 * @returns {Store} A Redux store that lets you read the state, dispatch actions
 * and subscribe to changes.
 */

export default function createStoreWithReduxables(reduxable, ...args) {
  // TODO: check for Reduxable inhetirance properly
  //       Don't know why `reduxable.constructor.prototype instanceof Reduxable` is not working
  const reducer = typeof reduxable === 'function' ? reduxable : reduxable.reduce

  const store = createStore(reducer, ...args)
  Reduxable._setStore(store)
	reduxable._initRoot && reduxable._initRoot()

	store._replaceReducer = store.replaceReducer
  store.replaceReducer = (reducer) => {
		Reduxable._setStore(store)
		reducer._initRoot && reducer._initRoot(true)
		// TODO: update reduxable options -> storages / injectDeps ...
    return store._replaceReducer(reducer.reduce || reducer)
	}

  return store
}
