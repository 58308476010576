// import Toast from 'common/helpers/Toast';
// import { _l } from 'common/app/lang';
// import { Util } from "lnwdropship/common/helpers";
// import { isBufferActive } from 'common/lib/redux-action-buffer';

import { api } from './api';

export { setupApiLoader } from './api'


export const apiMiddleware = options => (deps) => next => action => {
	// Not do anything if buffer active
	// if (isBufferActive()) return next(action);

	if (!action) return;

	const { dispatch, getState } = deps
	const { payload } = action;

	// has API field
	const hasApiField = payload && typeof payload.API === 'object';
	if (!hasApiField) return next(action);

	let { shouldThrow = false, shouldToastError = true } = options || {};

	if(payload.API.shouldThrow) shouldThrow = payload.API.shouldThrow
	if(typeof payload.API.shouldToastError !== 'undefined') shouldToastError = payload.API.shouldToastError

	const createAction = (suffix, payload) => ({
			...action, type: `${action.type}_${suffix}`, meta: { action, orig: { type: action.type, suffix } }, payload,
	});

	// skip function -> check state if there a need to call api
	const hasSkipFunction = payload && typeof payload.API.skip === 'function';

	if (hasSkipFunction) {
		// if (payload.API.skip(getState()) === true) {
		if (payload.API.skip(getState())) {
			if (typeof payload.API.cancel === 'function') payload.API.cancel()
			if (payload.API.success && typeof payload.API.success === 'function') {
				payload.API.success({ skip: true, ...deps })
			}
			return next(createAction('SKIP'));
			// debug(createAction('SKIP'))
			// return;
		}
	}

	let promise = api(payload.API, deps)
	const repeatCall = promise.repeatCall

	promise = promise.then((value) => {
		// skip from api = in case of repeat api call
		// if (value.skipped) {
		if(repeatCall){
			dispatch(createAction('REPEAT_SUCCESS', { ...value, logDisable: true }))
		} else {
			dispatch(createAction('SUCCESS', value))
			if (payload.API.successAfterReducer && typeof payload.API.successAfterReducer === 'function') {
				payload.API.successAfterReducer({ afterReducer: true, ...deps })
			}
		}
		return value
	}).catch((error) => {
		// remove circular
		const cache = [];
		let payload = null
		try {
			if(error){
				payload = JSON.parse(JSON.stringify(error, (key, value) => {
					if (typeof value === 'object' && value !== null) {
						if (cache.indexOf(value) !== -1) {
							// Circular reference found, discard key
							return;
						}
						// Store value in our collection
						cache.push(value);
					}
					return value;
				}));
			}
			dispatch(createAction('ERROR', payload));
		} catch (e) {
			dispatch(createAction('ERROR', e));
		}

		if(shouldToastError === true || (shouldToastError && shouldToastError(error))) {
			error.shouldToastError = true
		}else{
			error.shouldToastError = false
		}

		// // Not all errors need to be reported.
		// calc in errorToMessage()
		if (shouldThrow && shouldThrow(error)) {
			error.shouldThrow = true // report to sentry
		}else{
			error.shouldThrow = false
		}

		// make configureReporting to handle toast and sentry
		throw error;
	})

	if(repeatCall){
		// debug(createAction('SKIP'))
		// return;
		return next(createAction('REPEAT_START', { promise, meta: payload, logDisable: true }));
	}else{
		return next(createAction('START', { promise, meta: payload }));
	}
};
