import { getPlatformDep } from 'common/helpers/platformDeps';
import UI from 'common/helpers/UI';

const toastr = getPlatformDep('Toast')

// let toastr = () => getPlatformDep('Toast')
// TODO: Native

// https://github.com/CodeSeven/toastr
/*
 // Display a warning toast, with no title
 toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')

 // Display a success toast, with a title
 toastr.success('Have fun storming the castle!', 'Miracle Max Says')

 // Display an error toast, with a title
 toastr.error('I do not think that word means what you think it means.', 'Inconceivable!')

 // Immediately remove current toasts without using animation
 toastr.remove()

 // Remove current toasts using animation
 toastr.clear()

 // Override global options
 toastr.success('We do have the Kapua suite available.', 'Turtle Bay Resort', {timeOut: 5000})

 */

if (toastr && toastr.options) {
	if (UI.isMobileTemplate()) {
		toastr.options.positionClass = 'toast-top-right';
	} else {
		toastr.options.positionClass = 'toast-bottom-right';
	}

	if(typeof window != 'undefined') {
		window.__fasterToast = () => {
			toastr.options.timeOut = 500
			toastr.options.extendedTimeOut = 1
			return true
		}
		window.__clearToast = () => {
			toastr && toastr.clear()
			return true
		}
	}
}

export const Toast = {
	info: toastr ? toastr.info : () => null,
	success: toastr ? toastr.success : () => null,
	error: (message, title, optionsOverride = {}) => {
		// default = no timeout until click
		optionsOverride = {
			timeOut: optionsOverride.errorDetail ? 0 : 5000,
			extendedTimeOut: 0,
			//onclick: () => alert(`ERROR: #${errorCode}\n ${errStringLong} \n ${JSON.stringify(error)}`)
		 ...optionsOverride }
		 if(optionsOverride.errorDetail){
			 optionsOverride.onclick = () => alert(optionsOverride.errorDetail)
		 }
		return toastr ? toastr.error(message, title, optionsOverride) : () => null
	},
	warning: toastr ? toastr.warning : () => null,
	remove: toastr ? toastr.remove : () => null,
	clear: toastr ? toastr.clear : () => null,
	// info: () => toastr().info(),
	// success: () => toastr().success(),
	// error: () => toastr().error(),
	// warning: () => toastr().waring(),
	// remove: () => toastr().remove(),
	// clear: () => toastr().clear(),
}

export default Toast
