// https://blog.rescale.com/addressing-valuelink-deprecation-in-react-15/

/*
<input
	type="number"
  {...linkStateHelper(this,'company', ['budget'] )}
/>
<input
  onChange={linkState(this, 'company', ['budget'])}
  type="number"
  value={this.state.company.get('budget')}
/>

<input
  checked={this.state.showAdvancedOptions} // checked, not value!
  onChange={linkState(this, 'showAdvancedOptions')}
  type="checkbox"
/>
 */

import linkState from '../components/linkState'
import _ from 'common/helpers/_'

export const linkStateHelper = (instance, key, path = null, defaultValue = undefined, options = {}) => {
	const { byPassFunction = null, extra = {} } = options
	if(defaultValue) extra.defaultValue = defaultValue
	return {
		onChange: linkState(instance, key, path, byPassFunction),
		value: (path ? _.get(instance.state[key], path) : instance.state[key]) || defaultValue,
		...extra
	}
}