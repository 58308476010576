// https://lodash.com/docs/
export const _ = {
	clone: require('lodash/clone'),
	cloneDeep: require('lodash/cloneDeep'),

	isEqual: require('lodash/isEqual'),
	values: require('lodash/values'),
	merge: require('lodash/merge'),
	extend: require('lodash/extend'),
	get: require('lodash/get'),
	set: require('lodash/set'),
	has: require('lodash/has'),
	hasIn: require('lodash/hasIn'),
	unset: require('lodash/unset'),
	create: require('lodash/create'),

	escape: require('lodash/escape'),
	unescape: require('lodash/unescape'),
	reduce: require('lodash/reduce'),
	isSet: require('lodash/isSet'),
	isArray: require('lodash/isArray'),
	isObject: require('lodash/isObject'),
	transform: require('lodash/transform'),

	// array
	slice: require('lodash/slice'),
	range: require('lodash/range'),
	map: require('lodash/map'),
	filter: require('lodash/filter'),
	pick: require('lodash/pick'),
	pickBy: require('lodash/pickBy'),
	omit: require('lodash/omit'),
	omitBy: require('lodash/omitBy'),
	// find: require('lodash/find'),

	// string
	camelCase: require('lodash/camelCase'),
	kebabCase: require('lodash/kebabCase'),
	snakeCase: require('lodash/snakeCase'),
	startsWith: require('lodash/startsWith'),

	// function
	throttle: require('lodash/throttle'),
	debounce: require('lodash/debounce')
}
// const _ = require('lodsash')

export default _
