import { getPlatformDep } from 'common/helpers/platformDeps';

// TODO: Native

// http://github.hubspot.com/vex/api/basic/
const getVex = () => getPlatformDep('Dialog')


/*
 vex.dialog.buttons.YES.text = 'Okiedokie'
 vex.dialog.buttons.NO.text = 'Aahw hell no'
 */

export const Dialog = {
	_buttons: (options = {}) => {
		if (options.buttons && Array.isArray(options.buttons)) {
			const btns = options.buttons;
			options.buttons = []
			btns.map((btn) => {
				const value = btn.value || null

				const config = {
					...btn,
					text: btn.text || 'Button',
					type: (btn.primary ? 'sumbit' : null) || btn.type || 'button',
					className: btn.className || btn.primary ? 'vex-dialog-button-primary' : 'vex-dialog-button-secondary',
					click: btn.click || function ($vexContent, event) {
						this.value = value
						this.close()
					},
				}
				// options.buttons[value || 'NO'] = config
				options.buttons.push(config)
			})
		}
		return options
	},

	alert: async (options) => {
		if (typeof window == 'undefined') return
		const vex = await getVex()
		vex && vex.alert(options)
	},
	confirm: async (msg, callback, options) => {
		if (typeof window == 'undefined') return
		const vex = await getVex()
		options = Dialog._buttons(options || {})
		return vex.dialog.confirm({
			message: msg || 'แน่ใจ?',
			callback,
			...options,
		});
	},
	prompt: async (message, placeholder, callback, options) => {
		if (typeof window == 'undefined') return
		const vex = await getVex()
		options = Dialog._buttons(options || {})
		return vex.dialog.prompt({
			message,
			callback,
			placeholder,
			...options,
		});
	},
	open: async (message, options, callback) => {
		if (typeof window == 'undefined') return
		const vex = await getVex()
		// buttons
		options = Dialog._buttons(options || {})
		return vex.dialog.open({
			message,
			callback,
			...options,
		})
	},
}

export default Dialog
