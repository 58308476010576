
/* @flow */

// TODO: move to browser? or use other way check to screen size
// import { Util } from 'common/helpers'

import { isServer } from '@lib/base/common'

/*eslint-disable */

export const serverDefaultRenderAsMobile = true;

var _unlockServerRenderDefault = false
function unlockServerRenderDefault() {
	// console.log('unlockServerRenderDefault')
	_unlockServerRenderDefault = true

	_isLargeDesktopTemplate = isLargeDesktopTemplate(true);
	_isDesktopTemplate = isDesktopTemplate(true);
	_isTabletTemplate = isTabletTemplate(true);
	_isMobileTemplate = isMobileTemplate(true);
}
// TODO: Native

function isLargeDesktopTemplate(nocache) {
	if(isServer() || !_unlockServerRenderDefault) return !serverDefaultRenderAsMobile;
	if (!nocache) return _isLargeDesktopTemplate;
	if (typeof window === 'undefined' || !window.matchMedia) return false;
	return window.matchMedia('(min-width: 1400px)').matches;
}

function isDesktopTemplate(nocache) {
	if(isServer() || !_unlockServerRenderDefault) return !serverDefaultRenderAsMobile;
	if (!nocache) return _isDesktopTemplate;
	if (typeof window === 'undefined' || !window.matchMedia) return false;
	return window.matchMedia('(min-width: 1000px)').matches;
}

function isTabletTemplate(nocache) {
	if(isServer() || !_unlockServerRenderDefault) return false;
	if (!nocache) return _isTabletTemplate;
	if (typeof window === 'undefined' || !window.matchMedia) return false;
	return !window.matchMedia('(min-width: 1000px)').matches;
}

function isMobileTemplate(nocache) {
	// TODO: component helper
	if(isServer() || !_unlockServerRenderDefault) return serverDefaultRenderAsMobile;
	if (!nocache) return _isMobileTemplate;
	if (typeof window === 'undefined' || !window.matchMedia) return false;
	return !window.matchMedia('(min-width: 750px)').matches;
}

function isMobileTemplateChanged() {//from mobile to desktop or vice versa
	var current = isMobileTemplate();
	if (current != _isMobileTemplateChanged) {
		_isMobileTemplateChanged = current;
		return true;
	} else {
		_isMobileTemplateChanged = current;
		return false;
	}
}

function isTabletTemplateChanged() {//from mobile to desktop or vice versa
	var current = isTabletTemplate();
	if (current != _isTabletTemplateChanged) {
		_isTabletTemplateChanged = current;
		return true;
	} else {
		_isTabletTemplateChanged = current;
		return false;
	}
}

function isiOS() {
	if(typeof navigator == 'undefined') return false;
	return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
	// var iDevices = [
	// 	'iPad Simulator',
	// 	'iPhone Simulator',
	// 	'iPod Simulator',
	// 	'iPad',
	// 	'iPhone',
	// 	'iPod'
	// ];
	//
	// if (!!navigator.platform) {
	// 	while (iDevices.length) {
	// 		if (navigator.platform === iDevices.pop()){ return true; }
	// 	}
	// }
	//
	// return false;
}


function isFacebookApp() {
	if(typeof navigator == 'undefined') return false;
	var ua = navigator.userAgent || navigator.vendor || window.opera;
	return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
}

let _isLargeDesktopTemplate = isLargeDesktopTemplate(true);
let _isDesktopTemplate = isDesktopTemplate(true);
let _isTabletTemplate = isTabletTemplate(true);
let _isMobileTemplate = isMobileTemplate(true);
let _isMobileTemplateChanged = isMobileTemplate();
let _isTabletTemplateChanged = isTabletTemplate();

type UIType = {
	scrolltoID: any,
	scrollto: any,

	isLargeDesktopTemplate: any,
	isDesktopTemplate: any,
	isTabletTemplate: any,
	isMobileTemplate: any,
	isMobileTemplateChanged: any,
	isTabletTemplateChanged: any,
	isFacebookApp: any,
	isiOS: any,

	isInViewport: any,
	fullscreenChange: any,
	fullscreenOpen: any,
	fullscreenClose: any,

	compute: any
}


//fb fix bug
let _haveFocus = false
const _onFocus = function(){
	_haveFocus = true
}
const _onBlur = function(){
	_haveFocus = false
	setTimeout(function() {
		if(!_haveFocus){
			$('html, body').scrollTop(1)
			$('.modal-container').scrollTop = 1
			$('.modalDialog').focus()
		}
	}, 500);
}

export const UI: UIType = {
	unlockServerRenderDefault,

	// TODO: browser vs native
	scrolltoID: (target, space) => {
		UI.scrollto("#" + target, space)
	},

	scrollto: (target, space, duration = 500) => {
		var targetTop = 0
		if (!space) space = 0;
		// if($(target).length==0) return console.log("error " + target + " not found.");
		if ($(target).offset()) {
			targetTop = $(target).offset().top - space;
		}
		$('html, body').animate({ scrollTop: targetTop }, duration);
	},

	isLargeDesktopTemplate,
	isDesktopTemplate,
	isTabletTemplate,
	isMobileTemplate,
	isMobileTemplateChanged,
	isTabletTemplateChanged,
	isiOS,
	isFacebookApp,

	isInViewport: (ele) => {
		if (!ele || !$(ele).offset()) return false;
		var elementTop = $(ele).offset().top;
		var elementBottom = elementTop + $(ele).outerHeight();

		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();

		return elementBottom > viewportTop && elementTop < viewportBottom;
	},

	_fullscreenState: null,
	fullscreenChange: (open) => {
		if (open === true) {
			if (UI._fullscreenState !== true) UI.fullscreenOpen()
		} else {
			if (UI._fullscreenState !== false) UI.fullscreenClose()
		}
	},

	fullscreenOpen: () => {

		//save position
		if (UI.isMobileTemplate()) {
			UI._lastScrollTop = $('body').scrollTop();
			if (UI._lastScrollTop === 0)
				UI._lastScrollTop = $('html, body').scrollTop();
			// window.scrollTo(0, 0); //202210 fix body scroll modal


			if ($(".modal-container").length > 0 || true) //Todo if new condition
			{
				// https://stackoverflow.com/questions/10238084/ios-safari-how-to-disable-overscroll-but-allow-scrollable-divs-to-scroll-norma
				// TODO: fix ios bug
				$('html,document').on('touchmove', function (e) {
					e.preventDefault();
				});

				var scrolling = false;

				// Uses body because jquery on events are called off of the element they are
				// added to, so bubbling would not work if we used document instead.

				$('body').on('touchstart', '.modal-container', function (e) {

					// Only execute the below code once at a time
					if (!scrolling) {
						scrolling = true;
						if (e.currentTarget.scrollTop === 0) {
							e.currentTarget.scrollTop = 1;
						} else if (e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.offsetHeight) {
							e.currentTarget.scrollTop -= 1;
						}
						scrolling = false;
					}
				});

				// Prevents preventDefault from being called on document if it sees a scrollable div
				$('body').on('touchmove', '.modal-container', function (e) {
					e.stopPropagation();
				});
			}
			$('body').addClass('mobile-modal-open');
		}

		//202210 fix body scroll modal
		var initWidth = $('body').outerWidth();
		var initHeight = $('body').outerHeight();
		// $('html').data('previous-overflow', $('html').css('overflow'));
		var initTop = $(document).scrollTop();
		$('body').css('overflow', 'hidden');
		var marginR = $('body').outerWidth()-initWidth;
		var marginB = $('body').outerHeight()-initHeight;
		var top = $(document).scrollTop();
		if (initTop !== top){ //fix height ไม่เท่าเดิมเหมือนบีบเนื้อหาชดเชย scroll
			$('html, body').scrollTop(initTop)
		}
		$('body').css({'margin-right': marginR,'margin-bottom': marginB,'width': 'auto','height': 'auto'});

		$("body").addClass("modal-open");

		if(UI.isFacebookApp())
		{
			// https://stackoverflow.com/questions/13270659/detect-virtual-keyboard-vs-hardware-keyboard/47396515#47396515
			// #height-div{height: 10vh;}
			// var initialHeight = $("#height-div").height();
			$(document).on("focus", ".modal-container input,textarea", _onFocus)
			$(document).on("blur", ".modal-container input,textarea", _onBlur)
			// setTimeout(function(){
			// 	if((initialHeight*0.7) > $("#height-div").height()){//if the current viewport height is smaller than 70% of the original size, it means that the keyboard is up
			// 		//ENTER YOUR LOGIC HERE
			// 	}else if((initialHeight*0.7) < parent.$submit.height()){//if the current viewport height is larger than 70% of the original size, it means that the keyboard is down
			// 		//ENTER YOUR LOGIC HERE
			// 	}
			// },500);
			// });
		}

		UI._fullscreenState = true
	},

	fullscreenClose: () => {

		//202210 fix body scroll modal
		// $('html').css('overflow', $('html').data('previous-overflow'));
		$('body').css('overflow', 'unset');
		$('body').css({'margin-right': 0, 'margin-bottom': 0,'width':'100%','height':'100%'});

		$("body").removeClass("modal-open");
		$('body').removeClass('mobile-modal-open')

		if(UI.isFacebookApp()) {
			$(document).off("focus", ".modal-container input,textarea", _onFocus)
			$(document).off("blur", ".modal-container input,textarea", _onBlur)
		}
		// TODO: fix ios bug
		if (UI.isMobileTemplate()) {

			$('html,document').off('touchstart');
			$('html,document').off('touchmove');

		}

		// scroll back to last position
		if (UI.isMobileTemplate() && UI._lastScrollTop != null) {
			const lastScrollTop = UI._lastScrollTop
			$('html, body').scrollTop(lastScrollTop)
			setTimeout(() => $('html, body').scrollTop(lastScrollTop), 100)//in case browser delay
			UI._lastScrollTop = null
		}
		UI._fullscreenState = false
	},

	// view helper

	// vue computed var
	compute: (element, toBeCompute = null) => {
		if (toBeCompute === null) {
			toBeCompute = element.computed
		}
		if (toBeCompute === null) return {}
		const ret = {}
		for (var k in toBeCompute) {
			if (toBeCompute.hasOwnProperty(k)) {
				ret[k] = toBeCompute[k].bind(element)()
			}
		}
		return ret
	},

	// browser helper
	// getScrollParent: (element, includeHidden) => {
	//   var style = getComputedStyle(element);
	//   var excludeStaticParent = style.position === "absolute";
	//   var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;
	//
	//   if (style.position === "fixed") return document.body;
	//   for (var parent = element; (parent = parent.parentElement);) {
	//     style = getComputedStyle(parent);
	//     if (excludeStaticParent && style.position === "static") {
	//       continue;
	//     }
	//     if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent;
	//   }
	//
	//   return document.body;
	// }
	getScrollParent: (node) => {
		if (node == null || !node.parentNode) {
			return null;
		}

		if (node.scrollHeight > node.clientHeight) {
			return node;
		} else {
			return UI.getScrollParent(node.parentNode);
		}
	}

}


// Browser
if (typeof window !== 'undefined' && typeof $ !== 'undefined') {

	$(window).resize(function (e) {
		_isDesktopTemplate = UI.isDesktopTemplate(true);
		_isTabletTemplate = UI.isTabletTemplate(true);
		_isMobileTemplate = UI.isMobileTemplate(true);
	});
	window['UI'] = UI
}


export default UI

