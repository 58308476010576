
import React from 'react';
import PropTypes from 'prop-types';
import { updateServerContext } from '@lnw/base/serverContext'
import { connect } from "@lib/base/redux";

function _getCallerFile() {
	var backup = Error.prepareStackTrace
	try {
		var err = new Error();
		var callerfile;
		var currentfile, rootFile;
		Error.prepareStackTrace = function (err, stack) { return stack; };
		//currentfile = err.stack.shift().getFileName();
		currentfile = null //err.stack.shift().getFunctionName()
		rootFile = null
		var stack = ""
		while (err.stack.length) {
			const current = err.stack.shift()
			callerfile = current.getFunctionName();

			if(callerfile.indexOf('./') ==0 || callerfile.indexOf('modules') ==0  || callerfile.indexOf('src') ==0){
				if(//currentfile !== null &&
				currentfile !== callerfile) {
					stack += "> " + current.toString() + "\n";
				}
				if(rootFile == null) rootFile = callerfile
				currentfile = callerfile
			}

		}
		Error.prepareStackTrace = backup
		return { rootFile, stack }
	} catch (err) {
	}
	Error.prepareStackTrace = backup
	return {};
}


// reactCommon(component) | reactCommon({ debug: false },component) | reactCommon({ debug: false })(component)
export const reactCommon = (options = null, component = null) => {
	// console.log('reactCommon', options, component)
	// if(Util.empty(options) && component){
	// 	component = options
	// 	options = null
	// }

	if(options && (typeof options == 'function' || (options.prototype && options.prototype.render))){
		component = options
		options = null
	}
	if(component === null) {
		if(options !== null) {
			return (component) => reactCommon(options, component)
		}
		component = options
		options = {}
	}
	if(component === null){
		console.trace('reactCommon', 'component is null')
		return component
	}
	//check if already used?
	if(component.__reactCommon__) return component
	options = {
		debug: true, // display debug information
		styles: null, // auto add local css
		name: null, // override name
		compose: null, // HOC to add more function to stateless component
		states: null, // states: [ ['counter', 'setCounter', 0], ... ]
		server: null, // server context
		api: null, // load api to props
		catchError: false,
		connect: null,
		// TODO: more options

		...(options || {})
	};

	if(!options.name) options.name = component.name || component.displayName
	if(!options.name && component.constructor)
		options.name = component.constructor.name || component.constructor.displayName

	component = extendComponent(component, options)

	if(options.api){
		component = withApi(options.api, component)
	}

	if(options.styles) component = withStyle(options.styles, component)

	if(options.debug) component = debugView(component, options)

	if(options.states){
		// states: [ ['counter', 'setCounter', 0], ... ]
		if(!options.compose) options.compose = { states: options.states}
		else options.compose.states = [...options.compose.states, ...options.states]
	}

	if(options.compose){
		component = withCompose(options.compose, component)
	}

	if(options.server){
		component = withServerContext(options.server, component)
	}

	// TODO: debug?
	// if(options.catchError){
	// 	component = withCatchError(options, component)
	// }

	if(options.connect){
		component = connect(options.connect)(component)
	}

	component.__reactCommon__ = true
	return component
}

export const extendComponent = (component, options) => {
	const extendedComponents = require('_config/common').extendedComponents
	if(!extendedComponents) return component
	const name = options.name ||  component.name
	if(!name || !extendedComponents[name]) return component
	const output = extendedComponents[name](component)

	return output || component
}

export const withStyle = (styles, component) => {
	if(!styles && !styles.local) return component
	return extendRenderer(component, (result, props) => <div style={styles.local}>{result}</div>)
}

export const debugView = (component, options = {}) => {
	// if(!global.isTest() || (global.env && global.env.IS_SERVER)){
	const { isTest, isLocalhost } = require('@lib/base/helper')
	if(typeof isNative !== 'undefined' && isNative()) return component
	if(!isTest() && !isLocalhost()){
		return component
	}
	options.filename = _getCallerFile()
	component.__filename = options.filename
	if(global.STORYBOOK) return component // disable debug for storybook mode

	const name = options.name || component.name || 'unknown'
	// run on dev/test mode
	return extendRenderer(component, function (result, props) {
		// const params = {'data-debug-name': name, 'data-debug-props': JSON.stringify(props || this.props)}
		const params = {'data-debug-name': name, 'data-debug-file':options.filename.rootFile }
		// const cache = [];
		// const propsEle = <i {...params} dangerouslySetInnerHTML={{ __html: `<!-- ${name}.props: ${JSON.stringify(props || null, (key, value) => {
		// 		if (typeof value === 'object' && value !== null) {
		// 			if (cache.indexOf(value) !== -1) {
		// 				return;
		// 			}
		// 			// Store value in our collection
		// 			cache.push(value);
		// 			if(value && value.length && value.length > 10)
		// 				return [value[0],value[1],value[2],`+${value.length-3} more`]
		// 		}
		// 		if(typeof value === 'string') {
		// 			value = Util.trunc(value, 100)
		// 		}
		// 		return value;
		// 	}, 2)} -->` }}/>
		const propsEle = <i {...params} dangerouslySetInnerHTML={{ __html: `<!-- File stack : ${options.filename.stack} -->`}} />
		const render = (ele) => <React.Fragment>{propsEle}{ele}</React.Fragment>
		if(!result) result = this && this.children
		return render(result)
		// if(!result) return render(<span {...params}/>)
		// if(result.type !== 'div' && result.type !== 'span'  && result.type !== 'section') return render(<div {...params}>{result}</div>)
		// return React.cloneElement(result, params)
	})
}



const extendRenderer = (component, renderer) => {
	if(!component) { return component }

	const isComponent = component instanceof React.Component
	const isClass = !(typeof component == 'function' && !(component.prototype instanceof React.Component))
	const oldRender = isComponent ? component.render : ( isClass ? component.prototype.render : component )
	// const self = this

	const renderFunc = function (props) {
		if(!props && this) props = this.props
		let result = oldRender && oldRender.bind(this)(props, this)
		if(typeof result == 'undefined') console.error('render return undefined (return null instead)', component)
		return renderer.bind(this)(result, props, this)
	}

	if(isComponent){
		component.render = renderFunc
	}else if(isClass){
		component.prototype.render = renderFunc

	}else{
		const name = component.name || 'unknown'
		const baseComponent = component

		// TODO: fix 'this' problem in stateless with class child
		component = renderFunc
		// component = (props) => <RenderFunc {...props}/>
		// component = (props) => {
		// 	console.log('props', props)
		// 	// return renderFunc(props)
		// 	let result = oldRender && oldRender(props)
		// 	if(typeof result == 'undefined') console.error('render return undefined (return null instead)', component)
		// 	return renderer(result, props)
		// }
		// component.constructor.displayName = name // <-- use all same constructor -> useless for naming
		// newComponent.constructor.name = renderer.name
		try { // IOS8 / safari 8 bug
			Object.defineProperty(component, 'name', { value: name });
			component.name = name
			// Object.defineProperty(component.constructor, 'name', { value: name });
		} catch (e) {
		}
		// copy static props
		for (const props in baseComponent) if(baseComponent.hasOwnProperty(props)) component[props] = baseComponent[props]

		// component = toClass(component)
		// component.prototype.render = renderFunc
	}
	return component
}

/**
 * options = {
 * states: [ ['counter', 'setCounter', 0], ... ],
 * handlers: { increment: ({ setCounter }) => () => setCounter(n => n + 1) },
 * lifecycle: { componentDidMount: () => true, componentWillMount: () => true }
 * }
 */
export const withCompose = (options = {}, component) => {

	const { compose, withHandlers, withState, lifecycle } = require('recompose');
	const params = []

	if(options.states){
		options.states.map(state => params.push(withState.apply(null, state)))
	} // [['counter', 'setCounter', 0], ... ]

	if(options.handlers) params.push(withHandlers(options.handler))  // handler:{ func1: () => true }
	if(options.lifecycle) params.push(lifecycle(options.lifecycle)) //lifecycle: { componentDidMount: () => true }
	if(params.length == 0) return component

	// return component;
	// const _component = component

	// TODO: fix 'this' problem in stateless
	// TODO: Cannot set property 'props' of undefined
	// console.log('2', options, 'component', _component.name)
	// const enchance = compose.apply(null, params)(_component)
	// debugger
	// return function() {
	// 	console.log('arguments',arguments,options, 'this', this, 'component', _component.name)
	// 	// console.trace('trace')
	// 	const ret =  enchance.apply(this, arguments);
	// 	// const ret =  compose.apply(null, [])(component).apply(this, arguments);
	// 	console.log('after ret')
	// 	return ret;
	// }

	return compose.apply(this, params)(component);
	// return compose.apply(null, [])(component);

	// return compose(
	// 	withState('fullReviewOpened', 'setFullReviewOpened', false),
	// 	withHandlers({
	// 		toggleFullReviewOpened: ({ setFullReviewOpened }) => () => setFullReviewOpened(v => !v),
	// 	})
	// 	// withState('email', 'setEmail', ''),
	// 	// withHandlers({
	// 	// 	onChange: ({ setEmail }) => event => setEmail(() => event.target.value),
	// 	// 	submit: props => () => console.log(props),
	// 	// })
	// )(component);
}


// serverContext

export class ServerContext extends React.Component{
	static contextTypes = {
		// serverFetchPromises: PropTypes.array,
		serverContext: PropTypes.object
	}


	componentWillMount = function () {
		const { serverFetchPromises, serverContext } = this.context;

		if (serverFetchPromises) { // isServer

			this.context.serverContext = updateServerContext(this.props.context, serverContext)
		}
	}

	render(){
		return this.props.children
	}

}


export const withServerContext = (context, Component) => {
	return (props) => <ServerContext context={context}>
		<Component {...props}/>
	</ServerContext>
}


// serverContext
export const withApi = (apiOptions, Component) => {
	if(!apiOptions) return Component
	const { api, apiPreload2 } = require("@lnw/api");
	let c = apiPreload2((props, isServer) => {
		return api(apiOptions)
	}, apiOptions.options || {})(Component)

	c.prototype.getApiData = function () {
		if(!this.state) return { data: null, loading: false }
		return { data: (this.state._apiReturn && this.state._apiReturn.data) || null,
			loading: this.state._apiLoading || false }
	}
	return c
}


// serverContext

export class CatchError extends React.Component{
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		// const errorCode = Util.randomInt(10000,99999)
		this.setState({ hasError: true });
		const name = this.props.name || 'unknown_component'
		console.error("componentDidCatch", name, error)
		debug(error)
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, info);
		const { getPlatformDep } = require('common/helpers/platformDeps');
		const Raven = getPlatformDep('RavenInit')
		if(Raven){
			// Raven.setTagsContext({ errorCode });
			// Raven.captureException(error)
			Raven.withScope(function(scope) {
				//https://docs.sentry.io/enriching-error-data/context/?platform=browser
				// scope.setExtra("errorCode", errorCode);
				scope.setExtra("name", name);

				//TODO: grouping & fingerprint
				//https://docs.sentry.io/data-management/event-grouping/
				// scope.setFingerprint([method, path, err.statusCode]);
				Raven.captureException(error)
			});
		}
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <div class={"react-render-error name-" + this.props.name}/>;
		}
		return this.props.children
	}

}


export const withCatchError = (options, Component) => {
	return (props) => <CatchError name={options.name}>
		<Component {...props}/>
	</CatchError>
}

