export const exportAll = (exports, _helpers = {}) => {
	Object.keys(_helpers).forEach((key) => {
		if (key === 'default' || key === '__esModule') return;
		Object.defineProperty(exports, key, {
			configurable: true, enumerable: true, get: function get() {
				return _helpers[key];
			}
		});
	});
	return exports
}

export const moduleExportAll = (module, exports = {}, _helpers = {}) => {
	Object.keys(_helpers).forEach((key) => {
		if (key === 'default' || key === '__esModule') return;
		Object.defineProperty(exports, key, {
			configurable: true, enumerable: true, get: function get() {
				return _helpers[key];
			}
		});
	});
	Object.defineProperty(module, "exports", {
		configurable: true, enumerable: true, get: function get() {
			return exports;
		}
	});
}

global.exportAll = exportAll
global.moduleExportAll = moduleExportAll
