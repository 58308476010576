/**
 *
 * Loading
 *
 */

import PropTypes from 'prop-types';

import React from 'react';
import { View } from 'browser/components';
import Url from "@lib/base/Url";
import _ from 'common/helpers/_';
import { pure } from 'recompose'

export const Loading = pure((props) => {
	const { center } = props
	const props2 = _.clone(props)
	const style = { paddingTop: 20, paddingBottom: 20 , ...(props2.style || {})}
	if (center) style.textAlign = 'center'

	delete props2.center
	delete props2.style

	if (center) {
		return (
			<div class="row" style={{ borderBottom: 0 }}>
				<div class="twelve columns">
					<View style={style} {...props2}>
						{/*<i class="fa fa-spinner fa-pulse fa-2x fa-fw color_primary"/>*/}
						<div class="loadingLogo"><img src={Url.asset('/images/loading/preload_32x32.gif')} class="scale-with-grid"
																					alt="กำลังโหลดข้อมูล กรุณารอสักครู่..." title="กำลังโหลดข้อมูล กรุณารอสักครู่..."/></div>
					</View>
				</div>
			</div>
		)
	}

	return (
		<View style={style} {...props2}>
			<i class="fa fa-spinner fa-pulse fa-2x fa-fw color_primary"/>
		</View>
	)
})


Loading.propTypes = {
	center: PropTypes.bool,
	// prop2: React.PropTypes.object,
};


export default Loading;
