
export const updateServerContext = (context, serverContext) => {
	if(!serverContext) return serverContext
	if(context.cacheTime && !context.pageCacheTime) context.pageCacheTime = context.cacheTime
	if(context.pageCacheTime){
		if(!serverContext.pageCacheTime){
			serverContext.pageCacheTime = context.pageCacheTime
		}else if(serverContext.pageCacheTime > context.pageCacheTime){
			serverContext.pageCacheTime = context.pageCacheTime
		}
	}

	if(context.redirectUrl){
		serverContext.redirectUrl = context.redirectUrl
	}

	if(context.redirectUrlStatusCode){
		serverContext.redirectUrlStatusCode = context.redirectUrlStatusCode
	}

	if(context.missed){
		serverContext.missed = context.missed
	}

	if(context.private){ // disable render
		// console.log('context private', context)
		serverContext.private = context.private
	}

	if(context.preloadScripts){
		if(!serverContext.preloadScripts) serverContext.preloadScripts = []
		serverContext.preloadScripts = serverContext.preloadScripts.concat(context.preloadScripts)
	}

	if(context.preloadStyles){
		if(!serverContext.preloadStyles) serverContext.preloadStyles = []
		serverContext.preloadStyles = serverContext.preloadStyles.concat(context.preloadStyles)
	}


	if(context.headers){
		if(!serverContext.headers) serverContext.headers = []
		serverContext.headers = serverContext.headers.concat(context.headers)
	}


	if(context.footers){
		if(!serverContext.footers) serverContext.footers = []
		serverContext.footers = serverContext.footers.concat(context.footers)
	}

	return serverContext
}