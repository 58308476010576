/* @flow */
/* eslint-disable */


global.isReactLoaded = () => true

// use to detect file is loaded
global.reactAppJs = process.env.MAIN_MODULE

global.lang = function () {
	var aaaaa = $.extend([], arguments);
	var full_key = aaaaa.shift();
	if (typeof __lnw_lang_text == 'undefined' || typeof __lnw_lang_text[full_key] == 'undefined') {
		return full_key;
	}
	if (aaaaa.length > 0) {
		var _text = __lnw_lang_text[full_key];
		var aaa = aaaaa;
		aaa.unshift(_text);
		return sprintf.apply(this, aaa);
	} else {
		return __lnw_lang_text[full_key];
	}
}


// global helper

// global.getConst = () => require('_config/common/const').default
global.getConst = () => ( require('@lib/base/const').default || {})

global.isLocalhost = () => {
	return getConst().IS_LOCALHOST && process.env.NODE_ENV === 'development'
}

global.isTest = () => getConst().IS_TEST

global.inLnwCore = () => getConst().IN_LNWCORE

global.debug = require('debug')('reactUniversal')

global.isCacheDisabled = require('@lib/base/helper').isCacheDisabled


let _prototypeInited = false
const _prototypeInit = () => {
	if(_prototypeInited) return
	_prototypeInited = true
	// console.log('prototypeInit')
	String.prototype.trimLeft = function (charlist) {
		if (charlist === undefined) {
			charlist = ' ';
		}

		return this.replace(new RegExp(`^[${charlist}]+`), '');
	};

	String.prototype.trimRight = function (charlist) {
		if (charlist === undefined) {
			charlist = ' ';
		}

		return this.replace(new RegExp(`[${charlist}]+$`), '');
	};

	String.prototype.trimChar = function (charlist) {
		if (charlist === undefined) {
			charlist = '\s';
		}
		return this.replace(new RegExp(`^[${charlist}]+`), '').replace(new RegExp(`[${charlist}]+$`), '');
	};

// Util
	String.prototype.repeat = function (count) {
		if (count < 1) return '';
		let result = '',
			pattern = this.valueOf();
		while (count > 1) {
			if (count & 1) result += pattern;
			count >>= 1, pattern += pattern;
		}
		return result + pattern;
	};

	String.prototype.replaceAll = function (search, replacement) {
		const target = this;
		return target.replace(new RegExp(search, 'g'), replacement);
	};


// --- Error??
// Object.prototype.get = function (path) { return _.get(this, path) }
// Object.prototype.getIn = function (path) { return _.get(this, path) }
//
// Object.prototype.set = function (path, value) { return _.set(this, path, value) }
// Object.prototype.setIn = function (path, value) { return _.setIn(this, path, value) }

// Object.prototype.map = function (func, ctx) {
//   return Object.keys(this).map((key) => {
//     return func(this[key], key)
//   })
//   // return _.map(this, func, ctx)
// }

	Array.prototype.get = function (path) {
		const _ = require("common/helpers/_").default
		return _.get(this, path)
	}
	Array.prototype.getIn = function (path) {
		const _ = require("common/helpers/_").default
		return _.get(this, path)
	}

	Array.prototype.set = function (path, value) {
		const _ = require("common/helpers/_").default
		return _.set(this, path, value)
	}
	Array.prototype.setIn = function (path, value) {
		const _ = require("common/helpers/_").default
		return _.set(this, path, value)
	}

}


// https://gist.github.com/philfreo/68ea3cd980d72383c951
// Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
// throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
// to avoid the entire page breaking, without having to do a check at each usage of Storage.
if (typeof localStorage === 'object') {
	try {
		localStorage.setItem('localStorage', 1);
		localStorage.removeItem('localStorage');
	} catch (e) {
		// TODO: use other backup storage?
		Storage.prototype._setItem = Storage.prototype.setItem;
		Storage.prototype.setItem = function() {};
		console.error('Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.');
	}
}


// console.log('global Init')
_prototypeInit()

export const debug = global.debug
export const prototypeInit = _prototypeInit
