/* @flow */
// import _ from 'common/helpers/_';
// import { withRecords } from 'common/immutable';
// import { Record as ImmutableRecord, recordCreator, recordTemplate, transit } from 'common/immutable';
import { Record as ImmutableRecord, recordCreator, recordTemplate } from '@lib/base/immutable';
import invariant from 'invariant';
import Immutable from '@lib/base/seamless-immutable';

// const records = {};

// let transitWithRecords = null
// const transitRecords = () => {
// 	//if(!transitWithRecords){ // TODO: test
// 		transitWithRecords = transit.withRecords(
// 			// _.values(recordTemplate),
// 			_.values(recordCreator)
// 			// Seq(records).toSet().toArray()
// 		);
// 	//}
// 	return transitWithRecords
// }


// const fixForHotReload = (Record, previous) => {
//   if (!previous) return;
//   const names = Object.getOwnPropertyNames(previous);
//   const transitGuidKey = names.find(n => n.startsWith('transit$guid$'));
//   if (!transitGuidKey) return;
//   Record[transitGuidKey] = previous[transitGuidKey];
// };

// Transit allows us to serialize / deserialize immutable Records automatically.
export const fromJSON = (string: string) => Immutable.from(string);
export const toJSON = (object: any) => Immutable.asMutable(object);
// export const fromJSON = (string: string) => transitRecords().fromJSON(string);
// export const toJSON = (object: any) => transitRecords().toJSON(object);
// export const fromJSON = (string: string) => fromJS(JSON.parse(string));
// export const toJSON = (object: any) => JSON.stringify(object);


// Just a wrapper for ImmutableRecord with registration for the transit.
// This is aspect-oriented programming. It's for cross-cutting concerns.
export const Record = (defaultValues: Object | Function, name: string, prototypeOrClass: object) => {

	if (!name && prototypeOrClass.name) name = prototypeOrClass.name
	invariant(name && typeof name === 'string',
		'Transit Record second argument name must be a non empty string.');

	// TODO: node server reload fix
	// fixForHotReload
	const prev = recordTemplate[name]
	let transitGuidKey = null,
		transitGuidValue = null
	if (prev) {
		const names = Object.getOwnPropertyNames(prev.constructor);
		transitGuidKey = names.find(n => n.startsWith('transit$guid$'));
		transitGuidValue = prev.constructor[transitGuidKey]
	}

	const TransitImmutableRecord = ImmutableRecord(defaultValues, name, prototypeOrClass);

	// transit fix for hot reload
	if (prev && transitGuidKey && transitGuidValue) {
		// recordCreator[name][transitGuidKey] = transitGuidValue
		recordTemplate[name].constructor[transitGuidKey] = transitGuidValue
	}

	// fixForHotReload(TransitImmutableRecord, prev);
	// recordTemplate[name] = TransitImmutableRecord;
	return TransitImmutableRecord;
};