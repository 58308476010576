// Persistent storage config
// Declare what state's field to save in localstorage / persistent memory

import { default as base } from 'base/common/storages';
import { default as storage } from '../../common/storages';

// --- [STORAGES_IMPORT]
// --- [/STORAGES_IMPORT]

export default [
	...base,
	...storage,
// --- [STORAGES]
// --- [/STORAGES]
]

// callback after rehydrated
export const callback = ({ dispatch, getState, ...args }) => {
	// only load in cc mode
	const { CONST } = require('recruitment/common/helpers');
	let pathname
	if(typeof(args.history.getRoot())==="undefined"){
		pathname = window.location.pathname
	}else{
		pathname = args.history.getRoot().route.location.pathname
	}
	if (pathname === '/manage' || CONST.DEFAULT_ROUTE === 'manage') {
		const { fetchManager } = require('recruitment/common/manage/actions')
		dispatch(fetchManager())
	}
}
