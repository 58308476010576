// import { Cache } from '@lnw/base/cache';

let latestUrl = null
const getLatestUrl = () => latestUrl || require('@lnw/base/cache').Cache.localSyncGet('latestUrl', '/')

export const setUrl = (url) => {
	latestUrl = url
	require('@lnw/base/cache').Cache.localSet('latestUrl', url)
}

export const getUrl = () => {
	// if window
	if (typeof window != 'undefined' && window.location) return window.location.href
	const { getState } = require('common/configure/configureStore')

	// from state
	const state = getState && getState()
	if (state && state.location) return state.location.href

	if (process.env.SERVER_URL) return process.env.SERVER_URL
	return getLatestUrl()
}

export const getLocation = () => {
	// if window
	if (typeof window != 'undefined') return window.location

	const { getState } = require('common/configure/configureStore')
	// from state
	const state = getState && getState()
	if (state && state.location) return state.location
	const url = require('url');// TODO: import => 23kb

	return url.parse(process.env.SERVER_URL || getLatestUrl())
}

export const getRouter = () => {
	// TODO: native
	return typeof global.router != 'undefined' ? global.router : {}
}