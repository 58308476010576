import merge from 'lodash/merge';

let _platformDeps = {}
let _platformDepsAsync = {}


export const __setPlatformDeps = (platformDeps) => {
	_platformDeps = merge(platformDeps, _platformDeps)
}

export const setPlatformDep = (field, platformDep) => {
	// console.log('setPlatformDep', field)
	_platformDeps[field] = platformDep
}

export const getPlatformDep = (field, ...params) => {
	if (typeof _platformDeps[field] == 'function') return _platformDeps[field].apply(this, params)
	return _platformDeps[field]
}


setPlatformDep.Async = (field, platformDep) => {
	// console.log('setPlatformDep', field)
	_platformDepsAsync[field] = platformDep
}

getPlatformDep.Async = async (field, ...params) => {
	if (typeof _platformDepsAsync[field] == 'function') return await _platformDepsAsync[field].apply(this, params)
	return await _platformDepsAsync[field]
}

export const getPlatformDeps = () => _platformDeps
