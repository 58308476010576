export const isBrowser = () => {
	const is = (global.env && global.env.IS_BROWSER) || process.env.IS_BROWSER
	return is && is !== 'false'
}


export const isServer = () => {
	const is = (global.env && global.env.IS_SERVER) || process.env.IS_SERVER
	return is && is !== 'false'
}

export const isNative = () =>{
	const is = (global.env && global.env.IS_NATIVE) || process.env.IS_NATIVE
	return is && is !== 'false'
}

export const isServerless = () => {
	const is = (global.env && global.env.IS_SERVERLESS) || process.env.IS_SERVERLESS
	return is && is !== 'false'
}

export const getGlobal = () => {
	return global
}

export const setGlobal = (k, v) => {
	return global[k] = v
}

global.isBrowser = isBrowser
global.isServer = isServer
global.isNative = isNative
global.isServerless = isServerless

// global.debug = require('debug')(process.env.MAIN_MODULE)
