// import util from 'common/lib/lnw/util'
// --- [INJECT_IMPORT]
// --- [/INJECT_IMPORT]

// Dependency injection
// = Variable/Feaure to use in action
// // Example
// export const action = () => ({ getUid, now }) => {
//   getUid();
//   now();
//   return {
//     type: ACTION,
//     payload,
//   };
// };

import * as inject from '../../common/injectDeps'

export default {
	// util,
	...inject,
// --- [INJECT]
// --- [/INJECT]
}
