/*eslint-disable */
/* @flow weak */


import _ from "@lnw/base/_";
import LRUcache from "@lib/base/lru-jscache";
import { getPlatformDep } from "@lib/base/platformDeps";
import {purgePersistStore} from "common/configure/store/configureStore.lib";

// let lscache,
//   // Cacheman,
//   sessionCache,
//   localCache;

const lruCache = new LRUcache(100 /* lru size */)

// TODO: native

// Browser
const lscache = () => getPlatformDep('lscache') || {}

// Server
const sessionCache = () => getPlatformDep('sessionCache') || {}
const localCache = () => getPlatformDep('localCache') || {}
const sharedCache = () => getPlatformDep('sharedCache') || {}


// TODO: shared memory
//https://www.npmjs.com/package/mmap-object
// TODO: redis

export const CacheType = {
	SESSION: 'SESSION', // only session
	LOCAL: 'LOCAL', // permanent
	SHARED: 'SHARED', // shared with other process (redis / file)
	LRU: 'LRU', // in memory with 'least recent used'

	// ---
	LOCALSTORAGE: 'LOCALSTORAGE', // permanent
	VARIABLE: 'VARIABLE',
}

export class LnwCache {

	set(cacheType: CacheType, key: string, value: any, timeMinuteOrCookieDay?: number): Promise {
		if (cacheType == CacheType.SESSION) {
			return this.sessionSet(key, value, timeMinuteOrCookieDay);
		} else if (cacheType == CacheType.LOCAL || cacheType == CacheType.LOCALSTORAGE) {
			return this.localSet(key, value, timeMinuteOrCookieDay);
		} else if (cacheType == CacheType.LRU || cacheType == CacheType.VARIABLE) {
			return this.lruSet(key, value, timeMinuteOrCookieDay);
		}
		return null;
	}

	get(cacheType: CacheType, key: string, defaultValue: any = null): Promise {
		if (cacheType == CacheType.SESSION) {
			return this.sessionGet(key, defaultValue);
		} else if (cacheType == CacheType.LOCAL || cacheType == CacheType.LOCALSTORAGE) {
			return this.localGet(key, defaultValue);
		} else if (cacheType == CacheType.LRU || cacheType == CacheType.VARIABLE) {
			return this.lruGet(key, defaultValue);
		}
		return null;
	}

	remove(cacheType: CacheType, key: string): Promise {
		if (cacheType == CacheType.SESSION) {
			return this.sessionRemove(key);
		} else if (cacheType == CacheType.LOCAL || cacheType == CacheType.LOCALSTORAGE) {
			return this.localRemove(key);
		} else if (cacheType == CacheType.LRU || cacheType == CacheType.VARIABLE) {
			return this.lruRemove(key);
		}
	}

	clear(cacheType: CacheType): Promise {
		if (cacheType == CacheType.SESSION) {
			return this.sessionClear();
		} else if (cacheType == CacheType.LOCAL || cacheType == CacheType.LOCALSTORAGE) {
			return this.localClear();
		} else if (cacheType == CacheType.LRU || cacheType == CacheType.VARIABLE) {
			return this.lruClear();
		}
		return null
	}


	lruSet(key: string, value: any, timeMinute?: number): Promise {
		return new Promise((resolve, reject) => {
			if (timeMinute) {
				lruCache.setItem(key, value,  { expirationAbsolute: timeMinute * 60 })// { expirationSliding: timeMinute * 60 })
			}
			else {
				lruCache.setItem(key, value)
			}
			resolve(value)
		});
	}

	lruGet(key: string, defaultValue: any = null): Promise {
		return new Promise((resolve, reject) => {
			let ret = null;
			ret = lruCache.getItem(key)
			if (ret == null) {
				ret = this.returnDefaultValue(defaultValue);
			}
			resolve(ret)
			return ret;
		});
	}


	lruGetSync(key: string, defaultValue: any = null): Promise {
		let ret = null;
		ret = lruCache.getItem(key)
		if (ret == null) {
			ret = this.returnDefaultValue(defaultValue);
		}
		return ret;
	}

	lruGetIgnoreExpiredSync(key: string, defaultValue: any = null): Promise {
		let item = lruCache.getStorage().get(key)
		let isExpired = item ? lruCache.isExpired_(item) : true
		let ret = item ? item.value : null
		if (ret == null) {
			ret = this.returnDefaultValue(defaultValue);
		}
		return { value: ret, isExpired };
	}

	lruRemove(key: string): Promise {
		return new Promise((resolve, reject) => {
			lruCache.removeItem(key)
			resolve(true)
		});
	}

	lruClear(): Promise {
		return new Promise((resolve, reject) => {
			lruCache.clear()
			resolve(true)
		});
	}


	sessionSet(key: string, value: any, timeMinute?: number = null): Promise {
		if (process.env.IS_NATIVE) {
			return sessionCache().set(key, value, timeMinute)
		}
		if (!process.env.IS_BROWSER) {
			return sessionCache().set(key, value, timeMinute ? (timeMinute * 60) : null)
		}
		if (typeof window == 'undefined') return
		return new Promise((resolve, reject) => {
			if (typeof sessionStorage !== 'undefined') {
				try {
					if (timeMinute > 0) {
						// session with time
						this.localSet(`__session_${key}`, value, timeMinute);
						sessionStorage.setItem(`__session_alive_${key}`, 'yes');
					} else {
						sessionStorage.setItem(key, JSON.stringify(value));
					}
				} catch (e) {
					reject(e)
				}
			} else {
				this.localSet(key, value, timeMinute || 180);
			}
			resolve(value)
		});
	}

	sessionGet(key: string, defaultValue: any = null): Promise {
		if (!process.env.IS_BROWSER) {
			return new Promise((resolve, reject) => {
				sessionCache().get(key).then(v => {
					resolve(v == null ? this.returnDefaultValue(defaultValue) : v)
				}, reject)
			});
		}
		if (typeof window == 'undefined') return defaultValue

		return new Promise((resolve, reject) => {
			let ret = null;
			if (typeof sessionStorage !== 'undefined') {
				// session with time
				if (sessionStorage.getItem(`__session_alive_${key}`) == 'yes') {
					ret = this.localGet(`__session_${key}`);
					if (ret == null) {
						sessionStorage.removeItem(`__session_alive_${key}`);
					}
				}
				// session without time
				if (ret == null) {
					try {
						ret = JSON.parse(sessionStorage.getItem(key));
					} catch (e) {
						console.log("Can't parse sessionStorage");
						reject(e)
					}
				}
			} else {
				ret = this.localGet(key);
			}
			if (ret == null) {
				ret = this.returnDefaultValue(defaultValue);
			}
			resolve(ret)
			return ret;
		});
	}


	sessionSyncGet(key: string, defaultValue: any = null): Promise {
		if (!process.env.IS_BROWSER) {
			let ret = null
			sessionCache().get(key).then(v => {
				ret = v
			}, reject)
			if (ret === null) {
				ret = this.returnDefaultValue(defaultValue);
			}
			return ret
		}
		if (typeof window == 'undefined') return defaultValue

		let ret = null;
		if (typeof sessionStorage !== 'undefined') {
			// session with time
			if (sessionStorage.getItem(`__session_alive_${key}`) == 'yes') {
				ret = this.localGet(`__session_${key}`);
				if (ret == null) {
					sessionStorage.removeItem(`__session_alive_${key}`);
				}
			}
			// session without time
			if (ret == null) {
				try {
					ret = JSON.parse(sessionStorage.getItem(key));
				} catch (e) {
					console.log("Can't parse sessionStorage");
					reject(e)
				}
			}
		} else {
			ret = this.localGet(key);
		}
		if (ret == null) {
			ret = this.returnDefaultValue(defaultValue);
		}
		return ret;
	}

	sessionRemove(key: string): Promise {
		if (process.env.IS_NATIVE) {
			return sessionCache().del(key)
		}
		if (!process.env.IS_BROWSER) {
			return sessionCache().del(key)
		}
		if (typeof window == 'undefined') return

		return new Promise((resolve, reject) => {
			if (typeof sessionStorage !== 'undefined') {
				sessionStorage.removeItem(key);

				// session with time
				this.localRemove(`__session_${key}`);
				sessionStorage.removeItem(`__session_alive_${key}`);
			} else {
				this.localClear();
			}
			resolve(true)
		});
	}

	sessionClear(): Promise {
		if (!process.env.IS_BROWSER) {
			return sessionCache().clear()
		}
		if (typeof window == 'undefined') return
		return new Promise((resolve, reject) => {
			if (typeof sessionStorage !== 'undefined') {
				sessionStorage.clear();
			} else if (localStorage) {
				localStorage.clear();
			}
			resolve(true)
		});
	}

	localSet(key: string, value: any, timeMinute?: number): Promise {
		if (process.env.IS_NATIVE) {
			return localCache().set(key, value, timeMinute ? timeMinute : null)
		}
		if (!process.env.IS_BROWSER) {
			return localCache().set(key, value, timeMinute ? (timeMinute * 60) : null)
		}
		if (typeof window == 'undefined') return
		return new Promise((resolve, reject) => {
			if (!timeMinute) {
				try {
					localStorage.setItem(key, JSON.stringify(value));
				} catch (e) {

					if(e.toString && (e.toString().indexOf("quota") !== -1 && e.toString().indexOf("exceeded") !== -1)){ // safari vs chrome difference error text
						// purge store?
						try{
							console.log('storage exceeded the quota = try to purge')
							purgePersistStore()
							// size check
							// const remain = (1024 * 1024 * 5 - unescape(encodeURIComponent(JSON.stringify(localStorage))).length);
							localStorage.setItem('test', 'test')
						}catch(e){
							try{
								localStorage.clear()
							}catch(e){
								console.error(e)
							}
						}
					}else{
						return reject(e)
					}
				}
			} else {
				lscache().set(key, value, timeMinute);
			}
			resolve(value)
		});
	}

	localGet(key: string, defaultValue: any = null): Promise {
		if (!process.env.IS_BROWSER) {
			return new Promise((resolve, reject) => {
				localCache().get(key).then(v => {
					console.log('resolvePromise', v)
					resolve(v == null ? this.returnDefaultValue(defaultValue) : v)
				}, reject)
			});
		}
		if (typeof window == 'undefined') return defaultValue
		return new Promise((resolve, reject) => {
			let ret = localStorage.getItem(key);
			if (ret) ret = JSON.parse(ret);
			else {
				ret = lscache().get(key);
			}
			if (ret == null) {
				ret = this.returnDefaultValue(defaultValue);
			}
			resolve(ret)
			return ret;
		});
	}


	localSyncGet(key: string, defaultValue: any = null) {
		if (!process.env.IS_BROWSER) {
			let val = defaultValue
			if (localCache().length) {
				localCache().get(key, (err, v) => {
					if (!err) {
						val = v
					}
				})
			}

			return val == null ? this.returnDefaultValue(defaultValue) : val
		}
		if (typeof window == 'undefined') return defaultValue
		let ret = localStorage.getItem(key);
		if (ret) ret = JSON.parse(ret);
		else {
			ret = lscache().get(key);
		}
		if (ret == null) {
			ret = this.returnDefaultValue(defaultValue);
		}
		return ret;
	}

	localRemove(key: string): Promise {
		if (process.env.IS_NATIVE) {
			return localCache().remove(key)
		}
		if (!process.env.IS_BROWSER) {
			return localCache().del(key)
		}
		if (typeof window == 'undefined') return
		return new Promise((resolve, reject) => {
			lscache().remove(key);
			resolve(true)
		});
	}

	localClear(): Promise {
		if (process.env.IS_NATIVE) {
			return localCache().flush()
		}
		if (!process.env.IS_BROWSER) {
			return localCache().clear()
		}
		if (typeof window == 'undefined') return
		return new Promise((resolve, reject) => {
			lscache().flush();
			resolve(true)
		});
	}


	// use indexeddb (limit 50mb)
	localDataSet(key: string, value: any): Promise {
		if (!process.env.IS_BROWSER) {
			return this.localSet(key, value)
		}
		if (typeof window == 'undefined') return

		const ldb = require('./localdata').ldb
		if(!ldb) return this.localSet(key, value)


		return new Promise((resolve, reject) => {
			try {
				ldb.set(key, value, (e)=>{
					if (e && e.name == 'QuotaExceededError') {
						//TODO: Fallback code goes here
						ldb.clear()
						ldb.set(key, value, resolve)
					}else{
						resolve(value)
					}
				})
			} catch (e) {
				ldb.clear()
				ldb.set(key, value, resolve)
			}
		});
	}

	localDataGet(key: string, defaultValue: any = null): Promise {
		if (!process.env.IS_BROWSER) {
			return this.localGet(key, defaultValue)
		}
		if (typeof window == 'undefined') return defaultValue
		const ldb = require('./localdata').ldb
		if(!ldb) return this.localGet(key, defaultValue)
		return new Promise((resolve, reject) => {
			ldb.get(key, (ret)=> {
				if (ret == null) {
					ret = this.returnDefaultValue(defaultValue);
				}
				resolve(ret)
			})
		});
	}

	localDataRemove(key: string): Promise {
		if (!process.env.IS_BROWSER) {
			return this.localRemove(key)
		}
		if (typeof window == 'undefined') return
		const ldb = require('./localdata').ldb
		if(!ldb) return this.localRemove(key)
		return new Promise((resolve, reject) => {
			ldb.delete(key, () => {
				resolve(true)
			});
		});
	}

	localDataClear(): Promise {
		if (!process.env.IS_BROWSER) {
			return this.localClear()
		}
		if (typeof window == 'undefined') return
		const ldb = require('./localdata').ldb
		if(!ldb) return this.localClear()
		return new Promise((resolve, reject) => {
			ldb.clear(resolve);
		});
	}


	returnDefaultValue(defaultValue: any) {
		if (defaultValue == null) return null;
		if (Array.isArray(defaultValue)) {
			return _.extend([], defaultValue);
		} else if (typeof defaultValue == 'object') {
			return _.extend({}, defaultValue);
		}
		else {
			return defaultValue;
		}
	}


	sharedSet(key: string, value: any, timeMinute?: number = null): Promise {
		return sharedCache().set(key, value, timeMinute ? parseInt(timeMinute * 60) : null)
	}

	sharedGet(key: string, defaultValue: any = null): Promise {
		return sharedCache().get(key).then(v => {
			return v == null ? this.returnDefaultValue(defaultValue) : v
		})
	}

	sharedRemove(key: string): Promise {
		return sharedCache().del(key)
	}

	sharedClear(): Promise {
		return sharedCache().clear()
	}


	/**
	 * sample
	 * Util.cache("key",function(){ return data });
	 */
	// cache(key:string, callback?:()=>any, cacheType:CacheType = CacheType.SESSION, timeMinuteOrCookieDay?:number):any {
	//   let val = this.cacheGet(cacheType, key);
	//   if (val == null) {
	//     if (typeof callback != 'function') return null;
	//     val = callback();
	//     this.cacheSet(cacheType, key, val, timeMinuteOrCookieDay);
	//   }
	//   return val;
	// }


	/**
	 * sample
	 * Util.cacheCB("key",function(data){ $scope.data = data },function(setter){ LnwApi.Test(setter) });
	 */
	// cacheCB(key:string, cbReturnValue: (res:any)=>void, cbCalcValue: (setter:(val:any)=>void)=>any,
	//         // cacheCB(key:string, cbReturnValue, cbCalcValue,
	//         cacheType:CacheType = CacheType.SESSION, timeMinuteOrCookieDay?:number):any {
	//   const val = this.cacheGet(cacheType, key);
	//   if (val == null) {
	//     if (typeof cbCalcValue != 'function') return null;
	//     cbCalcValue((val) => {
	//       this.cacheSet(cacheType, key, val, timeMinuteOrCookieDay);
	//       cbReturnValue(val);
	//     });
	//   }
	//   cbReturnValue(val);
	// }

	// cacheCallback(cacheType:CacheType, key:string, timeMinuteOrCookieDay:number,
	//               dataCallback:(data:any)=>void,
	//               loadDataFunction:(dataCallback:any)=>void):void {
	//   //
	//   const data = this.cacheGet(cacheType, key);
	//   const _dataCallback = (data, _isCached) => {
	//     dataCallback(data);
	//     if (!_isCached && data !== null) {
	//       this.cacheSet(cacheType, key, data, timeMinuteOrCookieDay);
	//     }
	//   };
	//   if (data === null) {
	//     loadDataFunction(_dataCallback);
	//   } else {
	//     _dataCallback(data, true);
	//   }
	// }

}

export const Cache = new LnwCache();

export default Cache;
