/* eslint-disable */
var BUFFERED_ACTION_RETURN = 'redux-action-buffer: buffered action'

let bufferActive = 0;
let bufferActionsTimer = null
let forceBreak = false;

export const isBufferActive = () => bufferActive > 0


export function bufferActions(breaker, ignores, cb) {
	bufferActive++;
	let thisBufferActive = true
	var queue = []
	ignores = ignores || []
	if (typeof breaker === 'string') {
		var actionType = breaker
		breaker = function (action) {
			if (action.type === actionType) return true
			else return false
		}
	}

	// timeout
	if (!bufferActionsTimer) {
		bufferActionsTimer = setTimeout(() => forceBreak = true, 10000)
	}

	return function (store) {
		return function (next) {
			return function (action) {
				// console.log('next', next, action)
				if (!action) return;
				if (!thisBufferActive) return next(action)
				//ignore type
				if (action.type.indexOf("__SKIP_BUFFER") != -1) return next(action)

				let willBreak = breaker(action)
				//skip buffer in payload
				if (!willBreak && action.payload && action.payload.skip_buffer) return next(action)
				//ignore list
				if (typeof ignores == 'function') {
					if(!willBreak && ignores(action) === true) return next(action)
				}else if (ignores.indexOf(action.type) != -1) return next(action)

				if (willBreak || forceBreak) {
					thisBufferActive = false
					bufferActive--;
					var result = next(action)
					var queueResults = []
					queue.forEach(function (queuedAction) {
						var queuedActionResult = next(queuedAction)
						// debugger
						// by pass promise
						if(queuedAction.promise){
							if(queuedActionResult && queuedActionResult.payload && queuedActionResult.payload.promise){
								queuedActionResult.payload.promise.then(queuedAction._promiseResolve)
							}else{
								queuedAction._promiseResolve()
							}
						}
						queueResults.push(queuedActionResult)
					})
					cb && cb(null, {
						results: queueResults,
						queue: queue
					})
					return result
				} else {
					if(!action.promise){
						// by pass promise
						action.promise = new Promise((resolve) => {
							action._promiseResolve = resolve
						})
					}
					queue.push(action)
					// return null
					action.buffered = true
					return action
					// return BUFFERED_ACTION_RETURN
					// return next({ type: `${action.type}_BUFFERED`, payload: {} })
				}
			}
		}
	}
}