
export { applyMiddleware, compose } from 'redux';

// export { connect } from 'react-redux';
export { Provider } from 'react-redux';

// // --- Normal redux
// export { combineReducers } from '@lib/redux-seamless-immutable';
// export { createStore } from 'redux';
//
// // https://stackoverflow.com/questions/38652789/correct-usage-of-reduce-reducers/44371190#44371190
// // combineReducers creates nested state
// // reduceReducers creates flat state
// export reduceReducers from 'reduce-reducers'

// // --- Reduxable + new connect
import Reduxable from '@lib/reduxable'
export { connect, createStore, reduceReducers } from '@lib/reduxable'
export const combineReducers = (reducers) => new Reduxable(reducers)

// export default from 'redux';
